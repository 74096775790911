import { Injectable, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { map, retry, take, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { DataService } from '../shared/data/data.service';
import { HttpClient } from '@angular/common/http';
import { HandleErrorService } from '../shared/error/handle-error.service';
import { DebugService } from '../shared/debug/debug.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { ModalShoppingService } from './modal-shopping.service';
import { StoreService } from '../store/store.service';
import { UserService } from 'app/services/user/user.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ShoppingService implements OnInit, OnDestroy {
  private className: string = this.constructor.name;
  loadFacebookPixel: Promise<any>;

  public browsingMenu: boolean = false;
  public currParameters: Object = {};

  public storeCategories: Object | null = null;
  public selectedCategory: Object | null = null;
  public categoryProducts: Array<Object> | null = null;

  public selectedProduct: Object | null = null;
  public productSubItems: Array<Object> | null = null;
  public productComplements: Array<Object> | null = null;
  public productExtras: Array<Object> | null = null;

  public currOrder: Object | null = null;
  public subDeliveryFee: Subscription = null;

  public isLoadingSendOrder: boolean = false;

  maxCartStep: number = 4;
  currCartStep: number = 1;
  cartStepPrevious: number = 1;

  maxUserStep: number = 2;
  currUserStep: number = 1;
  userStepPrevious: number = 1;

  showNewUserForm: boolean = false;
  showForgotPasswordForm: boolean = false;

  // É analisado pelo campo que indica se exige subitem
  public subItemGroupName: Object = {
    0: {
      'subitem': {
        "singular": "Adicional",
        "plural": "Adicionais",
        "titleGroup": "Monte do seu jeito, escolha seus adicionais."
      },
      'complement': {
        "singular": "Borda",
        "plural": "Bordas",
        "titleGroup": "Selecione sua borda preferida."
      }
    },
    1: {
      'subitem': {
        "singular": "Sabor",
        "plural": "Sabores",
        "titleGroup": "Selecione o sabor desejado."
      },
      'complement': {
        "singular": "Borda",
        "plural": "Bordas",
        "titleGroup": "Selecione sua borda preferida."
      }
    }
  }

  @Output() toChangeDeliveryType: EventEmitter<Object> = new EventEmitter();
  @Output() toChangePaymentType: EventEmitter<Object> = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    public modalShoppingService: ModalShoppingService,
    protected afAuth: AngularFireAuth,
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected userService: UserService,
    protected dataService: DataService,
    protected storeService: StoreService,
    public router: Router,
    private location: Location,
    protected debug: DebugService) {

    this.debug.show(this.className, 'Iniciado...');

    this.currOrder = {
      'order_destination': null,
      'delivery_type': null,
      'payment_type': null,
      'payment_obs': null,
      'order_obs': '',
      'subtotal': 0,
      'grand_total': 0,
      'delivery_fee': 0,
      'discount_coupon': 0,
      'delivery_address': null,
      'products': [],
      'user': []
    };

  }

  /**
   * loadFacebookPixelCode
   */
  private loadFacebookPixelCode(url: string) {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /**
   * loadFbPixel
   */
  loadFbPixel(url: string) {
    this.loadFacebookPixel = new Promise((resolve) => {
      this.debug.show(this.className + '::loadFbPixel', 'importando Fb Pixel');
      this.loadFacebookPixelCode(url);
    });
  }

  resetStore() {
    this.currParameters = {};
    this.storeCategories = null;
    this.selectedCategory = null;
    this.selectedProduct = null;
    this.categoryProducts = null;
    this.productSubItems = null;
    this.productComplements = null;
    this.productExtras = null;
    this.dataService.currStore = { 'storeNickname': null, 'data': null };
  }

  resetOrder() {
    this.debug.show(this.className + '::resetOrder');

    this.currOrder = {
      'order_destination': null,
      'delivery_type': null,
      'payment_type': null,
      'payment_obs': null,
      'order_obs': '',
      'subtotal': 0,
      'grand_total': 0,
      'delivery_fee': 0,
      'discount_coupon': 0,
      'delivery_address': null,
      'products': [],
      'user': []
    };
    this.currOrder['products'] = [];
    this.calculateOrder();

    this.currOrder['user'] = [];

    // this.changeDeliveryType('B');
  }

  /**
   * backProductList
   */
  backProductList() {
    this.debug.show(this.className + '::backProductList');
    this.selectedProduct = null;
    this.productSubItems = null;
    this.productComplements = null;
    this.productExtras = null;
  }

  /**
   * changeDeliveryType
   */
  changeDeliveryType(type: string) {
    this.debug.show(this.className + '::changeDeliveryType::param(type)', type);

    this.toChangeDeliveryType.emit({ "newType": type });
  }

  /**
   * changePaymentType
   */
  changePaymentType(type: string) {
    this.debug.show(this.className + '::changePaymentType::param(type)', type);

    this.toChangePaymentType.emit({ "newType": type });
  }

  /**
   * addToOrder
   */
  addToOrder(product: Object) {
    this.debug.show(this.className + '::addToOrder::param(product)', product);

    // Necessario fazer a cópia usando (Object.assign) senão o produto(Object) é passado por referência.
    let copyProduct = Object.assign({}, product);
    // 

    if ('editing_cart' in copyProduct && copyProduct['editing_cart'] !== null) {
      this.currOrder['products'][copyProduct['editing_cart']] = copyProduct;
      this.calculateSubTotal();
    } else {
      this.currOrder['products'].push(copyProduct);
      this.addSubTotal(copyProduct);
    }
    this.calculateGrandTotal();

    this.toastr.success('Adicionado ' + product['itm_nome_exibicao'] + ' ao pedido.', 'Meu pedido', {
      positionClass: 'toast-bottom-right'
    });
  }

  /**
   * saleValue
   * @param collection 
   */
  private saleValue(collection: Array<Object>) {
    let sum = 0;

    for (const i in collection) {
      if (collection.hasOwnProperty(i)) {
        sum += collection[i]['itc_valor_venda'] * 1;
        sum += collection[i]['itm_valor_fixo_adicional'] * 1;
        sum += collection[i]['itc_valor_venda_extra'] * 1;
      }
    }
    return sum;
  }

  /**
   * highestValue
   * @param collection 
   */
  private highestValue(collection: Array<Object>) {
    let sum = 0;
    let biggest = 0;
    let biggestItem: Object = null;

    for (const i in collection) {
      if (collection.hasOwnProperty(i) && (collection[i]['itc_valor_venda'] * 1) > biggest) {
        biggest = collection[i]['itc_valor_venda'] * 1;
        biggestItem = collection[i];
      }
      sum += collection[i]['itm_valor_fixo_adicional'] * 1;
      sum += collection[i]['itc_valor_venda_extra'] * 1;
    }
    sum += biggest;
    biggestItem['highestValue'] = true;
    return sum;
  }

  /**
   * apportionmentValue
   * @param collection 
   * @param defaultLengthCollection 
   */
  private apportionmentValue(collection: Array<Object>, defaultLengthCollection: number) {
    let sum = 0;
    let apportionment = 0;

    for (const i in collection) {
      if (collection.hasOwnProperty(i)) {

        if (collection[i]['itc_valor_venda'] * 1 == 0.0) {
          apportionment = 0;
        } else {
          let commonDivider = 0;
          if (collection.length > defaultLengthCollection) {
            commonDivider = collection.length;
          } else {
            commonDivider = defaultLengthCollection;
          }
          apportionment = (commonDivider / collection.length * (collection[i]['itc_valor_venda'] * 1));
        }

        sum += apportionment;
        sum += collection[i]['itm_valor_fixo_adicional'] * 1;
        sum += collection[i]['itc_valor_venda_extra'] * 1;
      }
    }
    return sum;
  }

  /**
   * averageValue
   * @param collection 
   */
  private averageValue(collection: Array<Object>) {
    let sum = 0;
    let average = 0;

    for (const i in collection) {
      if (collection.hasOwnProperty(i)) {
        average = ((collection[i]['itc_valor_venda'] * 1) / collection.length);
      }
      sum += average;
      sum += collection[i]['itm_valor_fixo_adicional'] * 1;
      sum += collection[i]['itc_valor_venda_extra'] * 1;
    }
    return sum;
  }

  /**
   * calculateProductVariations
   * @param product 
   */
  private calculateProductVariations(product: Object) {
    this.debug.show(this.className + '::calculateProductVariations::param(product)', product);

    // let variationType = ['selectedSubItems', 'selectedComplements', 'selectedExtras'];
    let params = [
      { "collection": 'selectedSubItems', "mode": 'itc_forma_calculo_sabor' },
      { "collection": 'selectedComplements', "mode": 'itc_forma_calculo_borda' }
    ];
    let sum = 0;

    for (const i in params) {
      if (Array.isArray(product[params[i]['collection']]) && (product[params[i]['collection']]).length > 0) {
        switch (product[params[i]['mode']] * 1) {
          case 2:
            sum += this.highestValue(product[params[i]['collection']]);
            break;
          case 3:
            sum += this.apportionmentValue(product[params[i]['collection']], (product['itc_padrao_subitens'] * 1));
            break;
          case 4:
            sum += this.averageValue(product[params[i]['collection']]);
            break;
          default:
            sum += this.saleValue(product[params[i]['collection']]);
            break;
        }
      }
    }

    return sum;
  }

  private rounding(currValue) {

    if (this.currParameters && typeof (this.currParameters['arredondar']) !== 'undefined' && this.currParameters['arredondar'] !== null) {
      const roundingType: number = this.currParameters['arredondar'];
      const intPart: number = Math.abs(Math.floor(currValue));
      const decPart: number = +((currValue - intPart).toFixed(2));

      if (decPart > 0.00 && (roundingType * 1) == 0.00) {
        if (decPart >= 0.50) {
          return intPart + 1;
        } else {
          return intPart;
        }
      } else if (decPart > 0.00 && (roundingType * 1) == 0.25) {
        if (decPart >= 0.88) {
          return intPart + 1;
        } else if (decPart >= 0.63) {
          return intPart + 0.75;
        } else if (decPart >= 0.38) {
          return intPart + 0.50;
        } else if (decPart >= 0.13) {
          return intPart + 0.25;
        } else {
          return intPart;
        }
      } else if (decPart > 0.00 && (roundingType * 1) == 0.50) {
        if (decPart >= 0.75) {
          return intPart + 1;
        } else if (decPart >= 0.25) {
          return intPart + 0.50;
        } else {
          return intPart;
        }
      }
    }
    return currValue;
  }

  /**
   * productValue
   * @param product 
   */
  private productValue(product: Object) {
    this.debug.show(this.className + '::productValue::param(product)', product);

    if (product['itm_item_montagem'] * 1 > 0) {
      product['updated_product_value'] = this.rounding((product['itm_preco_exibicao'] * 1) + this.calculateProductVariations(product) + ((product['itc_preco_adicional_subitens']) ? parseFloat(product['itc_preco_adicional_subitens']) : 0));
    } else {
      product['updated_product_value'] = product['itm_preco_exibicao'] * 1;
    }

    return product['updated_product_value'];
  }

  /**
   * addSubTotal
   * @param product 
   */
  private addSubTotal(product: Object) {
    this.debug.show(this.className + '::addSubTotal::param(product)', product);

    this.currOrder['subtotal'] += this.productValue(product);
  }

  /**
   * calculateSubTotal
   */
  private calculateSubTotal() {
    this.debug.show(this.className + '::calculateSubTotal');
    this.currOrder['subtotal'] = 0;

    for (let product in this.currOrder['products']) {
      this.currOrder['subtotal'] += this.productValue(this.currOrder['products'][product]);
    }

  }

  /**
   * calculateGrandTotal
   */
  public calculateGrandTotal() {
    this.debug.show(this.className + '::calculateGrandTotal');

    this.currOrder['grand_total'] = this.currOrder['subtotal'] + this.currOrder['delivery_fee'] - this.currOrder['discount_coupon'];
    if (this.currOrder['grand_total'] < 0) {
      this.currOrder['grand_total'] = 0;
    }
  }

  /**
   * calculateOrder
   */
  public calculateOrder() {
    this.debug.show(this.className + '::calculateOrder');

    this.calculateSubTotal();
    this.calculateGrandTotal();
  }

  /**
   * reloadDeliveryFee
   */
  public reloadDeliveryFee() {
    this.debug.show(this.className + '::reloadDeliveryFee');

    if (this.currOrder['delivery_address'] && 'cdd_id' in this.currOrder['delivery_address'] && 'bro_id' in this.currOrder['delivery_address']) {
      this.storeService.getDeliveryFeeDistrict(this.dataService.currStore['storeNickname'], this.currOrder['delivery_address']['cdd_id'], this.currOrder['delivery_address']['bro_id']).pipe(
        take(1)
      ).subscribe(
        deliveryFee => {
          if ('lxbc_valor_entrega' in deliveryFee) {
            this.currOrder['delivery_fee'] = deliveryFee['lxbc_valor_entrega'] * 1
            this.calculateGrandTotal();
          }
        }
      )
    } else {
      this.debug.show(this.className + '::reloadDeliveryFee', 'Não foi possível ler o endereço do pedido atual.', false);
    }
  }

  /**
   * confirmOrder
   */
  public confirmOrder() {
    this.debug.show(this.className + '::confirmOrder');

    if (this.userService.userData) {
      this.isLoadingSendOrder = true;
      this.currOrder['user'] = this.userService.userData;
      this.saveCurrOrder();
    } else {
      this.modalShoppingService.showActionModal();
    }

  }

  /**
   * saveCurrOrder
   */
  private saveCurrOrder() {
    this.debug.show(this.className + '::saveCurrOrder');

    this.http.post(environment.hungryApiUrl + "order", this.currOrder)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        catchError(this.httpError.handleHttpError)
      ).subscribe(data => {

        this.isLoadingSendOrder = false;

        alert('Pedido enviado, obrigado!');

        this.resetOrder();
        this.closeCartModal();
        this.router.navigate(['/resume']);
        this.debug.show(this.className + '::saveCurrOrder::SALVO', data);
      });
  }

  /**
   * selectAddress
   * @param address 
   */
  selectAddress(address: Object) {
    this.debug.show(this.className + '::selectAddress::param(address)', address);

    // Necessario fazer a cópia usando (Object.assign) senão o produto(Object) é passado por referência.
    let copyAddress = Object.assign({}, address);
    // 
    this.currOrder['delivery_address'] = copyAddress;
    this.currOrder['delivery_type'] = 'E';
    this.reloadDeliveryFee();
    this.modalShoppingService.hideActionModal();
  }

  /**
   * getUserDeliveryAddress
   */
  public getUserDeliveryAddress() {
    this.debug.show(this.className + '::getUserDeliveryAddress');

    if (this.userService.userSessionData$) {
      this.userService.userSessionData$.subscribe(sessionData => {
        if (sessionData && sessionData.uid) {
          this.http.get(environment.hungryApiUrl + "/user/deliveryaddresses/" + sessionData.uid + "?store=" + this.dataService.currStore['storeNickname'])
            .pipe(
              map(resp => (this.dataService.extract(resp))),
              take(1),
              retry(2),
              catchError(this.httpError.handleHttpError)
            ).subscribe(resp => {
              this.userService.userData['enderecos_entrega'] = resp['data'];
            });
        } else {
          this.debug.show(this.className + '::getUserDeliveryAddress', 'Dados da sessão não carregados!', false);
        }
      });
    } else {
      this.debug.show(this.className + '::getUserDeliveryAddress', 'Dados da sessão do usuário ainda não carregados', false);
    }

  }

  private getStoreCategoriesByLjNickname(reqLjNickname: string): Observable<Object> {
    this.debug.show(this.className + '::getStoreCategoriesByLjNickname::param(reqLjNickname)', reqLjNickname);

    return this.http.get(environment.hungryApiUrl + "store/" + reqLjNickname + "/categories")
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  public getCategories() {
    this.debug.show(this.className + '::getCategories');

    this.getStoreCategoriesByLjNickname(this.dataService.currStore['storeNickname']).pipe(
      take(1)
    ).subscribe(
      result => {
        this.storeCategories = result['data'];
      }
    );
  }

  private getCategoryProductsByCategorySlug(categorySlug: string): Observable<Object> {
    this.debug.show(this.className + '::getCategoryProductsByCategorySlug::param(categorySlug)', categorySlug);

    return this.http.get(environment.hungryApiUrl + "store/" + this.dataService.currStore['storeNickname'] + "/products/" + categorySlug)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  public getCategoryProducts() {
    this.debug.show(this.className + '::getCategoryProducts');

    if (this.selectedCategory !== null) {
      this.getCategoryProductsByCategorySlug(this.selectedCategory['itg_palavra_chave']).pipe(
        take(1)
      ).subscribe(
        response => {
          this.categoryProducts = response['data'];
        }
      );
    }
  }

  /**
   * getProductSubitemsByProductIdAndSizeId
   */
  private getProductSubitemsByProductIdAndSizeId(productId: number, sizeId: number): Observable<Object | null> {
    this.debug.show(this.className + '::getProductSubitemsByProductIdAndSizeId::param(productId, sizeId)', productId + ', ' + sizeId);
    return this.http.get(environment.hungryApiUrl + "product/" + productId + "/subitems?size=" + sizeId)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  /**
   * getProductComplementsByProductIdAndSizeId
   */
  private getProductComplementsByProductIdAndSizeId(productId: number, sizeId: number): Observable<Object> {
    this.debug.show(this.className + '::getProductComplementsByProductIdAndSizeId::param(productId, sizeId)', productId + ', ' + sizeId);

    return this.http.get(environment.hungryApiUrl + "product/" + productId + "/complements?size=" + sizeId)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  /**
   * getProductSubitems
   */
  public getProductSubitems(): any {
    this.debug.show(this.className + '::getProductSubitems');
    if (this.selectedProduct && this.productSubItems === null) {
      if ('itm_id' in this.selectedProduct && 'tmh_id' in this.selectedProduct) {
        this.getProductSubitemsByProductIdAndSizeId(this.selectedProduct['itm_id'], this.selectedProduct['tmh_id']).pipe(
          take(1)
        ).subscribe(
          response => {
            this.productSubItems = response['data'];
          }
        );
      }
    }
  }

  /**
   * getProductComplements
   */
  public getProductComplements() {
    this.debug.show(this.className + '::getProductComplements');

    if (this.selectedProduct && this.productComplements === null) {
      if ('itm_id' in this.selectedProduct && 'tmh_id' in this.selectedProduct) {
        this.getProductComplementsByProductIdAndSizeId(this.selectedProduct['itm_id'], this.selectedProduct['tmh_id']).pipe(
          take(1)
        ).subscribe(
          response => {
            this.productComplements = response['data'];
          }
        );
      }
    }
  }

  public selectProduct(product: Object | null, productCartIndex: Number = null) {
    this.debug.show(this.className + '::selectProduct::param(product)', product);

    if (product) {
      // Necessario fazer a cópia usando (Object.assign) senão o produto(Object) é passado por referência.
      let copyProduct = Object.assign({}, product);
      // 
      this.selectedProduct = copyProduct;
      this.selectedProduct['selectedSubItems'] = [];
      this.selectedProduct['selectedExtras'] = [];
      this.selectedProduct['selectedComplements'] = [];
      this.selectedProduct['editing_cart'] = productCartIndex;

    } else {
      this.selectedProduct = null;
    }
    this.productSubItems = null;
    this.productComplements = null;
    this.productExtras = null;
  }

  public selectCategory(category: Object | null) {
    this.debug.show(this.className + '::selectCategory::param(category)', category);
    if (category) {
      if (this.selectedCategory === null || this.selectedCategory['itg_id'] !== category['itg_id']) {
        // Necessario fazer a cópia usando (Object.assign) senão o category(Object) é passada por referência.
        let copyCategory = Object.assign({}, category);
        // 
        this.selectedCategory = category

        this.selectedProduct = null;
        this.categoryProducts = null;
        this.productSubItems = null;
        this.productComplements = null;
        this.productExtras = null;

        this.getCategoryProducts();
      }
    } else {
      this.selectedCategory = null;
    }
  }

  public selectStore(storeNickname: string | null) {
    this.debug.show(this.className + '::selectStore::param(storeNickname)', storeNickname);

    if (storeNickname) {
      this.dataService.currStore['storeNickname'] = storeNickname;
      this.getStoreByLjNickname(storeNickname);
    } else {
      this.dataService.currStore['storeNickname'] = null;
      this.currOrder['order_destination'] = null;
    }

    this.selectedCategory = null;
    this.selectedProduct = null;
    this.categoryProducts = null;
    this.productSubItems = null;
    this.productComplements = null;
    this.productExtras = null;
  }

  private getStoreByLjNickname(reqLjNickname: string) {
    this.debug.show(this.className + '::getStoreByLjNickname::param(reqLjNickname)', reqLjNickname);

    this.http.get(environment.hungryApiUrl + "store/" + reqLjNickname)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      ).subscribe(data => {
        if (data['parametros']) {
          this.defineParameters(data['parametros']);
          this.setDefaultDeliveryType();
        }
        this.currOrder['order_destination'] = data['lj_id'] * 1;
        this.dataService.currStore['data'] = data;
      });
  }

  private setDefaultDeliveryType() {
    if (this.currParameters['aceitarEntrega'] && !this.currParameters['aceitarRetirada']) {
      this.debug.show(this.className, 'SHOOPPING APENAS DELIVERY');
    } else if (!this.currParameters['aceitarEntrega'] && this.currParameters['aceitarRetirada']) {
      this.setDeliveryTypeToTakeOut();
      this.debug.show(this.className, 'SHOOPPING APENAS RETIRADA');
    } else if (this.currParameters['aceitarEntrega'] && this.currParameters['aceitarRetirada']) {
      this.setDeliveryTypeToTakeOut();
      this.debug.show(this.className, 'SHOOPPING RETIRADA E DELIVERY');
    } else {
      alert('Loja fechada no momento!');
    }
  }

  private defineParameters(params) {

    this.currParameters = {};

    for (let currParam in params) {
      switch (params[currParam][0]['ljp_tipo']) {
        case 'number':
          this.currParameters[currParam] = ((params[currParam][0]['ljp_valor']) ? +params[currParam][0]['ljp_valor'] : null);
          break;
        case 'boolean':
          this.currParameters[currParam] = ((params[currParam][0]['ljp_valor'] == 'true') ? true : false);
          break;
        case 'timestamp' || 'time' || 'date' || 'string':
          this.currParameters[currParam] = ((params[currParam][0]['ljp_valor']) ? params[currParam][0]['ljp_valor'] : null);
          break;
        default:
          this.currParameters[currParam] = params[currParam][0]['ljp_valor'];
          break;
      }
    }
  }

  /**
   * selectSubitem
   */
  public selectSubitem(subitem: Object | null) {
    this.debug.show(this.className + '::selectSubitem::param(subitem)', subitem);

    if (subitem) {
      this.selectedProduct['selectedSubItems'].push(subitem);
    }
  }

  /**
   * selectComplement
   */
  public selectComplement(complement: Object | null) {
    this.debug.show(this.className + '::selectComplement::param(complement)', complement);

    if (complement) {
      this.selectedProduct['selectedComplements'].push(complement);
    }
  }

  /**
   * acceptsMoreSubitems
   */
  public acceptsMoreSubitems(): boolean {
    return (this.selectedProduct['selectedSubItems'].length < (this.selectedProduct['itc_padrao_subitens'] * 1));
  }

  /**
   * acceptsMoreSubitems
   */
  public acceptsMoreComplements(): boolean {
    return (this.selectedProduct['selectedComplements'].length < (this.selectedProduct['itc_max_bordas'] * 1));
  }

  /**
   * allowSubitems
   */
  public allowSubitems(product: Object | null): boolean {
    if (product) {
      return !(product['itc_padrao_subitens'] === null || (product['itc_padrao_subitens'] * 1) === 0);
    }
  }

  /**
   * allowComplements
   */
  public allowComplements(product: Object | null): boolean {
    if (product) {
      return !(product['itc_max_bordas'] === null || (product['itc_max_bordas'] * 1) === 0);
    }
  }

  async scrollToObj(objId) {
    await this.delay(200);
    document.getElementById(objId).scrollIntoView({
      behavior: 'smooth'
    });
  }

  public delay(ms: number): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });
  }

  /**
   * setDeliveryTypeToDefaultUserAddress
   */
  public setDeliveryTypeToDefaultUserAddress() {
    if (this.userService.userData !== null) {

      const user = this.userService.userData;
      for (var address in user['enderecos']) {
        if ((user['enderecos'][address]['etp_id'] * 1) == 1) {
          this.currOrder['delivery_address'] = user['enderecos'][address];
          this.reloadDeliveryFee();
          break;
        }
      }
      if (this.currOrder['delivery_address'] == null) {
        this.modalShoppingService.showActionModal('address');
      }

    } else {
      this.modalShoppingService.showActionModal('address');
    }
    this.debug.show(this.className + '::setDeliveryTypeToDefaultUserAddress', 'Selecionando: Entrega');
    this.currOrder['delivery_type'] = 'E';
  }

  /**
   * setDeliveryTypeToTakeOut
   */
  public setDeliveryTypeToTakeOut() {
    this.debug.show(this.className + '::setDeliveryTypeToTakeOut', 'Selecionando: Balcão');
    this.currOrder['delivery_type'] = 'B';
    this.currOrder['delivery_address'] = null;
    this.currOrder['delivery_fee'] = 0;
    this.currOrder['payment_type'] = null;
    this.currOrder['payment_obs'] = null;
    this.calculateGrandTotal();
  }

  /**
   * setNullDeliveryType
   */
  public setNullDeliveryType() {
    this.debug.show(this.className + '::setNullDeliveryType');
    this.currOrder['delivery_type'] = null;
    this.currOrder['delivery_address'] = null;
    this.currOrder['delivery_fee'] = 0;
    this.currOrder['payment_type'] = null;
    this.currOrder['payment_obs'] = null;
    this.calculateGrandTotal();
  }

  /**
   * selectPaymentType
   */
  selectPaymentType(paymentType) {
    this.debug.show(this.className + '::selectPaymentType', paymentType);
    if (this.currOrder['payment_type'] !== paymentType) {
      this.currOrder['payment_obs'] = "";
      this.currOrder['payment_type'] = paymentType;
    }
  }

  ngOnInit() {
    this.browsingMenu = false;
  }

  ngOnDestroy() {
    this.debug.show(this.className, 'ngOnDestroy');
    this.resetOrder();
    this.resetStore();
  }

  openLoginModal() {
    $('#loginModal').modal('show').addClass('animated fadeInDown');
  }

  closeLoginModal() {
    $('#loginModal').modal('hide').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight fadeInDown');
  }

  openCartModal(cartStep: number = 1) {
    if (cartStep != -1) {
      this.currCartStep = cartStep;
    }
    $('#cartModal').modal('show').addClass('animated fadeInDown');
  }

  closeCartModal() {
    this.userService.userDataNewAddressForm = false;
    $('#cartModal').modal('hide').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight fadeInDown');
  }

  formatShortHour(hour) {
    return hour.replace(/(\:\d\d)$/, "");
  }

  async lastCartStep() {
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutLeft');
    await this.delay(150);
    this.cartStepPrevious = this.currCartStep;
    this.currCartStep = this.maxCartStep;
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInRight');
  }

  async nextCartStep() {
    this.userService.userDataNewAddressForm = false;
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutLeft');
    await this.delay(150);
    this.cartStepPrevious = this.currCartStep;
    if (this.currCartStep < this.maxCartStep) {
      this.currCartStep++;
    }
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInRight');
  }

  async setCartStep(cartStep: number) {
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutLeft');
    await this.delay(150);
    this.cartStepPrevious = this.currCartStep;
    if (cartStep >= 1 && cartStep <= this.maxCartStep) {
      this.currCartStep = cartStep;
    }
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInRight');
  }

  async previousCartStep() {
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutRight');
    await this.delay(150);
    if (this.currCartStep > 1) {
      if (this.currCartStep == this.cartStepPrevious || this.currCartStep < this.cartStepPrevious) {
        this.currCartStep--;
      } else {
        this.currCartStep = this.cartStepPrevious;
      }
    } else {
      this.currCartStep = 1;
      this.closeCartModal();
      this.closeLoginModal();
    }
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInLeft');
  }

  async previousUserStep() {
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutRight');
    await this.delay(150);
    if (this.currUserStep > 1) {
      if (this.currUserStep == this.userStepPrevious || this.currUserStep < this.userStepPrevious) {
        this.currUserStep--;
      } else {
        this.currUserStep = this.userStepPrevious;
      }
    } else {
      this.currUserStep = 1;
      this.closeLoginModal();
    }
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInLeft');
  }

  async nextUserStep() {
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutLeft');
    await this.delay(150);
    this.userStepPrevious = this.currUserStep;
    if (this.currUserStep < this.maxUserStep) {
      this.currUserStep++;
    }
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInRight');
  }

  goToStores() {
    this.resetOrder();
    this.resetStore();
    this.router.navigate(['/stores']);
  }

}