import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { DebugService } from 'app/services/shared/debug/debug.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private className: string = this.constructor.name;

  private swUpdate: SwUpdate;

  constructor(
    private debug: DebugService,
    private toastr: ToastrService,
    public update: SwUpdate,
    public push: SwPush
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  ngOnInit() {

    const userAgent = window.navigator.userAgent.toLowerCase();

    // Detects if device is on iOS 
    const isAndroid = () => {
      return /android/.test(userAgent);
    }

    // Detects if device is on iOS 
    const isIos = () => {
      return /iphone|ipad|ipod/.test(userAgent);
    }

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => (('standalone' in (window as any).navigator) && ((window as any).navigator.standalone) || (window.matchMedia('(display-mode: standalone)').matches));

    if (!this.update.isEnabled) {
      this.debug.show(this.className + "::ngOnInit", 'Atualizações estão desativadas', false);
    } else {
      this.debug.show(this.className + "::ngOnInit", 'Atualizações estão ativas', true);
    }

    this.update.checkForUpdate();

    this.checkUpdates();

    this.update.available.subscribe(update => {
      this.debug.show(this.className + "::ngOnInit", 'Atualização disponível', true);
      this.toastr.show("Fizemos algumas melhorias, clique aqui para atualizar!", "Hungry Delivery", {
        enableHtml: true,
        closeButton: true,
        disableTimeOut: true,
        positionClass: 'toast-bottom-center',
        toastClass: 'ngx-toastr bg-red-to-orange-230 toaster-install'
      }).onTap
        .subscribe(() => this.activateUpdates());
    });

  }

  activateUpdates() {
    this.update.activateUpdate().then(() => window.location.reload());
  }

  public checkUpdatesInterval = null;

  checkUpdates() {

    this.checkUpdatesInterval = setInterval(() => {
      this.debug.show(this.className + "::checkUpdates", 'Verificando atualizações...');
      this.update.checkForUpdate().then(() => this.debug.show(this.className + "::checkUpdates", 'Atualizações verificadas!'));
    }, (30 * 1000));

    // this.appRef.isStable.subscribe((isStable) => {
    //   console.log('App estável!');
    //   console.log(isStable);

    //   if(isStable){
    //     // const timeInterval = interval(12 * 60 * 60 * 1000);
    //     const timeInterval = interval(5 * 1000);

    //     timeInterval.subscribe(() => {
    //       this.update.checkForUpdate().then(() => console.log('Atualizações verificadas!'));
    //     })
    //   }
    // });
  }

  ngOnDestroy() {
    clearInterval(this.checkUpdatesInterval);
  }
}