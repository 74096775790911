import { Component, OnInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';

declare var $: any;

@Component({
  selector: 'modal-cart-content-checkout',
  templateUrl: './modal-cart-content-checkout.component.html',
  styleUrls: ['./modal-cart-content-checkout.component.css']
})
export class ModalCartContentCheckoutComponent implements OnInit {
  private className: string = this.constructor.name;

  public paymentTypeSelected: string = null;

  constructor(
    public userService: UserService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  removeOrderProduct(productIndex) {
    this.shoppingService.currOrder['products'].splice(productIndex, 1);
    this.shoppingService.calculateOrder();
  }

  editOrderProduct(product: Object, productIndex: Number) {
    this.debug.show(this.className + '::editOrderProduct::param(product)', product);

    this.shoppingService.selectProduct(product, productIndex);

    for (let subitem in product['selectedSubItems']) {
      this.shoppingService.selectSubitem(Object.assign({}, product['selectedSubItems'][subitem]));
    }

    for (let complement in product['selectedComplements']) {
      this.shoppingService.selectComplement(Object.assign({}, product['selectedComplements'][complement]));
    }

  }

  confirmOrder() {
    if (this.auth.isLoggedIn) {
      this.shoppingService.confirmOrder();
    } else {
      // this.modalShoppingService.showActionModal();
    }
  }
}