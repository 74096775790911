import { Routes } from "@angular/router";
import { AuthGuard } from "./services/shared/auth/auth.guard";

import { Dashboard1Component } from "./views/dashboards/dashboard1.component";
import { Dashboard2Component } from "./views/dashboards/dashboard2.component";
import { Dashboard3Component } from "./views/dashboards/dashboard3.component";
import { Dashboard4Component } from "./views/dashboards/dashboard4.component";
import { Dashboard41Component } from "./views/dashboards/dashboard41.component";
import { Dashboard5Component } from "./views/dashboards/dashboard5.component";

import { StarterViewComponent } from "./views/appviews/starterview.component";
import { LoginComponent } from "./views/appviews/login.component";

import { BlankLayoutComponent } from "./components/common/layouts/blankLayout.component";
import { BasicLayoutComponent } from "./components/common/layouts/basicLayout.component";
import { TopNavigationLayoutComponent } from "./components/common/layouts/topNavigationLayout.component";

// HUNGRY DELIVERY SHOPPING
import { BasicShoppingLayoutComponent } from './components/common/layouts/basic-shopping-layout/basic-shopping-layout.component';

// HUNGRY DELIVERY
import { OpenOrdersComponent } from "./views/modules/orders/open-orders/open-orders.component";
import { FinishedOrdersComponent } from "./views/modules/orders/finished-orders/finished-orders.component";
import { FavoriteStoresComponent } from "./views/modules/stores/favorite-stores/favorite-stores.component";
import { AllStoresComponent } from "./views/modules/stores/all-stores/all-stores.component";
import { ProfileDetailsComponent } from "./views/modules/users/profile-details/profile-details.component";
import { ProfileAddressComponent } from "./views/modules/users/profile-address/profile-address.component";
import { ProfileContactComponent } from "./views/modules/users/profile-contact/profile-contact.component";
import { StoreComponent } from './views/modules/shopping/store/store.component';
import { ShowcaseComponent } from './views/modules/shopping/stores/showcase/showcase.component';

export const ROUTES: Routes = [
  
  // HUNGRY DELIVERY
  // Pedidos
  {
    path: 'orders', component: BasicLayoutComponent,
    children: [
      { path: 'open-orders', component: StarterViewComponent, canActivate: [AuthGuard] },
      // { path: 'open-orders', component: OpenOrdersComponent, canActivate: [AuthGuard], data: {permissionCod: '001-0001'} },
      { path: 'finished-orders', component: FinishedOrdersComponent, canActivate: [AuthGuard] }
    ]
  },
  // Estabelecimentos
  // {
  //   path: 'stores', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'favorite-stores', component: FavoriteStoresComponent, canActivate: [AuthGuard] },
  //     { path: 'all-stores', component: AllStoresComponent, canActivate: [AuthGuard] }
  //   ]
  // },
  // Usuários
  // {
  //   path: 'users', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'profile-details', component: ProfileDetailsComponent, canActivate: [AuthGuard] },
  //     // { path: 'profile-address', component: ProfileAddressComponent, canActivate: [AuthGuard] },
  //     // { path: 'profile-contact', component: ProfileContactComponent, canActivate: [AuthGuard] }
  //   ]
  // },
  // Paginas públicas para pedidos
  {
    path: 'store/:storeNickname', component: BasicShoppingLayoutComponent,
    children: [
      { path: '', component: StoreComponent, canActivate: [AuthGuard], data: { allowPublicMode: true } },
      { path: 'category', component: StoreComponent, canActivate: [AuthGuard], data: { allowPublicMode: true } },
      { path: 'category/:categorySlug', component: StoreComponent, canActivate: [AuthGuard], data: { allowPublicMode: true } },
      // { path: 'category', component: StoreComponent, canActivate: [AuthGuard], data: {allowPublicMode: true}, runGuardsAndResolvers: 'always' },
      // { path: 'category/:categorySlug', component: StoreComponent, canActivate: [AuthGuard], data: {allowPublicMode: true}, runGuardsAndResolvers: 'always' },
    ]
  },
  // EXAMPLES
  // {
  //   path: 'dashboards', component: BasicLayoutComponent,
  //   children: [
  //     { path: 'dashboard1', component: Dashboard1Component, canActivate: [AuthGuard] },
  //     { path: 'dashboard2', component: Dashboard2Component, canActivate: [AuthGuard] },
  //     { path: 'dashboard3', component: Dashboard3Component, canActivate: [AuthGuard] },
  //     { path: 'dashboard4', component: Dashboard4Component, canActivate: [AuthGuard] },
  //     { path: 'dashboard5', component: Dashboard5Component, canActivate: [AuthGuard] }
  //   ]
  // },
  {
    path: '', component: BasicShoppingLayoutComponent,
    children: [
      { path: '', component: ShowcaseComponent },
      { path: 'stores', component: ShowcaseComponent }
    ]
  },

  // Main redirect
  { path: '', redirectTo: 'resume', pathMatch: 'full' },
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'resume', component: StarterViewComponent, canActivate: [AuthGuard] }
    ]
  },
  // {
  //   path: '', component: BlankLayoutComponent,
  //   children: [
  //     { path: 'login', component: LoginComponent },
  //   ]
  // },

  // Handle all other routes
  // { path: '**', redirectTo: 'stores', canActivate: [AuthGuard] }
  { path: '**', redirectTo: 'stores' },
  { path: 'login', redirectTo: 'stores' }
];
