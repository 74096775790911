import { Component, OnInit } from '@angular/core';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DataService } from 'app/services/shared/data/data.service';

declare var $: any;

@Component({
  selector: 'modal-new-user-form',
  templateUrl: './modal-new-user-form.component.html',
  styleUrls: ['./modal-new-user-form.component.css']
})
export class ModalNewUserFormComponent implements OnInit {

  constructor(
    public dataService: DataService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
  ) { }

  ngOnInit() {
  }

  async nextUserStep(){
    const inputNewUserName: any = document.getElementById('nusr_email');
    const inputNewUserPass: any = document.getElementById('nusr_pass');
    const inputNewUserConfirmPass: any = document.getElementById('nusr_confirm_pass');

    this.modalShoppingService.isLoading = true;
    if (this.dataService.isValidEmail(inputNewUserName.value)) {
      if (inputNewUserPass.value === inputNewUserConfirmPass.value) {
        if (this.dataService.isStrongerPassword(inputNewUserPass.value)) {
          this.modalShoppingService.newUserEmail = inputNewUserName.value;
          this.modalShoppingService.newUserPass = inputNewUserPass.value;
          this.shoppingService.nextUserStep();

        } else {
          alert('Sua senha é muito fácil');
        }
      } else {
        alert('As senhas não são iguais');
      }
    } else {
      alert('Email inválido');
    }
    this.modalShoppingService.isLoading = false;
  }

  async previousCartStep(){
    this.shoppingService.previousCartStep();
    await this.shoppingService.delay(150);
    this.shoppingService.showNewUserForm = false;
  }

  async showUserLoginForm(){
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutRight');
    await this.shoppingService.delay(150);
    this.shoppingService.showNewUserForm = false;
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInLeft');
  }
}
