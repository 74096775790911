import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";

import { BsDropdownModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BasicLayoutComponent } from "./basicLayout.component";
import { BlankLayoutComponent } from "./blankLayout.component";
import { TopNavigationLayoutComponent } from "./topNavigationLayout.component";

import { NavigationComponent } from "./../navigation/navigation.component";
import { FooterComponent } from "./../footer/footer.component";
import { TopNavbarComponent } from "./../topnavbar/topnavbar.component";
import { TopNavigationNavbarComponent } from "./../topnavbar/topnavigationnavbar.component";
import { BasicShoppingLayoutComponent } from './basic-shopping-layout/basic-shopping-layout.component';
import { TopShoppingNavbarComponent } from '../topnavbar/top-shopping-navbar/top-shopping-navbar.component';

@NgModule({
  imports: [
    ModalModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [
    // DynamicModalComponent,
    // ModalContentLoginComponent,
    // ModalContentNewUserComponent,
    // ModalContentNewUserDataComponent,
    // ModalContentAddressComponent,
    // ModalContentNewUserResumeDataComponent,
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
    BasicShoppingLayoutComponent,
    TopShoppingNavbarComponent,
  ],
  exports: [
    FooterComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    NavigationComponent,
    TopNavigationLayoutComponent,
    TopNavbarComponent,
    TopNavigationNavbarComponent,
    BasicShoppingLayoutComponent,
    TopShoppingNavbarComponent,
  ],
})

export class LayoutsModule { }
