import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { RouterModule } from '@angular/router';
import { ROUTES } from '../../../app.routes';

import { StoreComponent } from './store/store.component';
import { AdvertisingComponent } from './store/advertising/advertising.component';
import { CategoryComponent } from './store/category/category.component';
import { CartComponent } from './store/cart/cart.component';
import { HelpInformationComponent } from './store/help-information/help-information.component';
import { ProductListComponent } from './store/product-list/product-list.component';
import { TimingInformationComponent } from './store/timing-information/timing-information.component';
import { ProductOptionsComponent } from './store/product-options/product-options.component';
import { SubitemListComponent } from './store/product-options/subitem/subitem-template.component';
import { ComplementListComponent } from './store/product-options/complement/complement-template.component';

// Componentes
import { ModalContentLoginComponent } from 'app/views/modules/shopping/store/modal-content-login/modal-content-login.component';
import { ModalContentNewUserComponent } from 'app/views/modules/shopping/store/modal-content-new-user/modal-content-new-user.component';
import { ModalContentNewUserDataComponent } from 'app/views/modules/shopping/store/modal-content-new-user-data/modal-content-new-user-data.component';
import { ModalContentListAddressesComponent } from 'app/views/modules/shopping/store/modal-content-list-addresses/modal-content-list-addresses.component';
import { ModalContentAddressDataComponent } from 'app/views/modules/shopping/store/modal-content-address-data/modal-content-address-data.component';
import { ModalContentClosedStoreComponent } from './store/modal-content-closed-store/modal-content-closed-store.component';
import { ModalCartContentProductsComponent } from './store/modal-cart-content-products/modal-cart-content-products.component';
import { ModalCartContentDeliveryTypeComponent } from './store/modal-cart-content-delivery-type/modal-cart-content-delivery-type.component';
import { ModalCartContentPaymentTypeComponent } from './store/modal-cart-content-payment-type/modal-cart-content-payment-type.component';
import { ModalCartContentCheckoutComponent } from './store/modal-cart-content-checkout/modal-cart-content-checkout.component';
import { ModalCartContentUserComponent } from './store/modal-cart-content-user/modal-cart-content-user.component';
import { ModalCartContentNewAddressComponent } from './store/modal-cart-content-new-address/modal-cart-content-new-address.component';
import { ModalNewUserFormComponent } from './store/modal-new-user-form/modal-new-user-form.component';
import { ModalUserFormComponent } from './store/modal-user-form/modal-user-form.component';
import { ModalNewUserDataFormComponent } from './store/modal-new-user-data-form/modal-new-user-data-form.component';
import { ShowcaseComponent } from './stores/showcase/showcase.component';
import { ModalUserForgotpassFormComponent } from './store/modal-user-forgotpass-form/modal-user-forgotpass-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    AlertModule,
    CarouselModule,
    RouterModule.forRoot(ROUTES)
  ],
  declarations: [
    ModalContentLoginComponent,
    ModalContentNewUserComponent,
    ModalContentNewUserDataComponent,
    ModalContentListAddressesComponent,
    ModalContentAddressDataComponent,

    StoreComponent, 
    AdvertisingComponent, 
    CategoryComponent, 
    CartComponent, 
    HelpInformationComponent, 
    ProductListComponent, 
    TimingInformationComponent, 
    ProductOptionsComponent, 
    SubitemListComponent,
    ComplementListComponent,
    ModalContentClosedStoreComponent,
    ModalCartContentProductsComponent,
    ModalCartContentDeliveryTypeComponent,
    ModalCartContentPaymentTypeComponent,
    ModalCartContentCheckoutComponent,
    ModalCartContentUserComponent,
    ModalCartContentNewAddressComponent,
    ModalNewUserFormComponent,
    ModalUserFormComponent,
    ModalNewUserDataFormComponent,
    ShowcaseComponent,
    ModalUserForgotpassFormComponent,
  ],
  exports: [
    ModalContentLoginComponent,
    ModalContentNewUserComponent,
    ModalContentNewUserDataComponent,
    ModalContentListAddressesComponent,
    ModalContentAddressDataComponent,
  ]
})
export class ShoppingModule { }
