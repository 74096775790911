import { Injectable, Output, EventEmitter } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { DataService } from '../shared/data/data.service';
import { HttpClient } from '@angular/common/http';
import { HandleErrorService } from '../shared/error/handle-error.service';
import { DebugService } from '../shared/debug/debug.service';
import { AuthService } from '../shared/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { StoreService } from '../store/store.service';
import { UserService } from 'app/services/user/user.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ModalShoppingService {
  private className: string = this.constructor.name;

  public newUserEmail: string = "";
  public newUserPass: string = "";

  public isLoading: boolean = false;
  public currContent: string = null;
  public requestedContent: string = null;

  public contentData: object = null;

  @Output() callActionModal: EventEmitter<Object> = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    protected afAuth: AngularFireAuth,
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected userService: UserService,
    protected dataService: DataService,
    protected storeService: StoreService,
    public router: Router,
    protected debug: DebugService) {

    this.debug.show(this.className, 'Iniciado...');
  }

  public setContent(content: string) {
    this.debug.show(this.className + '::setContent::param', content);

    this.requestedContent = content;
    this.currContent = content;

    if (this.currContent === 'login' || this.currContent === 'newUser') {
      $('#dynamicModal > .modal-dialog').addClass('modal-xl modal-login bglogin').removeClass('modal-default bgdefault');
    } else {
      $('#dynamicModal > .modal-dialog').addClass('modal-default bgdefault').removeClass('modal-xl modal-login bglogin');
    }
  }

  /**
   * showActionModal
   */
  showActionModal(reqContent = 'login', reqContentData = null) {
    this.debug.show(this.className + '::showActionModal');
    this.callActionModal.emit({ "toOpen": true, "content": reqContent, "contentData": reqContentData });
    this.contentData = reqContentData;
  }

  /**
   * hideActionModal
   */
  hideActionModal() {
    this.debug.show(this.className + '::hideActionModal');
    this.callActionModal.emit({ "toOpen": false });
    this.contentData = null;
  }
}
