import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { DebugService } from '../debug/debug.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {
  private className: string = "HandleErrorService";

  constructor(
    protected debug: DebugService,
    private toastr: ToastrService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  public handleHttpError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // Ocorreu um erro do lado do cliente ou na conexão

      console.log('%cERROR >> ' + ('HandleErrorService::handleHttpError ClientSide').toUpperCase(), "background:#fff0f0; color:#ff0000;padding:6px 10px;");
      console.log(error.error.message);

      // Retorna um observable uma mensagem amigável ao usuário
      // alert("Uma possível instabilidade na sua rede não permitiu que a sua solicitação fosse atendida. Tente novamente em alguns instantes.");
      return throwError({ "error": true, "message": "Uma possível instabilidade na sua rede não permitiu que a sua solicitação fosse atendida. Tente novamente em alguns instantes." });
    } else {
      // Ocorreu um erro no backend do host solicitado, é possível que alguma informação sobre o erro esteja no corpo da resposta.

      console.log('%cERROR >> ' + ('HandleErrorService::handleHttpError ServerSide').toUpperCase(), "background:#fff0f0; color:#ff0000;padding:6px 10px;");
      console.log(error);

      // alert("Infelizmente sua solicitação não pode ser atendida no momento pelo servidor. Tente novamente em alguns instantes.");
      return throwError({ "error": true, "message": "Infelizmente sua solicitação não pode ser atendida no momento pelo servidor. Tente novamente em alguns instantes." });
    }
  };

}
