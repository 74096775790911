import { Observable } from 'rxjs/Observable';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import { DebugService } from '../../debug/debug.service';
import { AuthService } from '../../auth/auth.service';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { HandleErrorService } from '../../error/handle-error.service';
import { environment } from 'environments/environment';
import { DataService } from '../../data/data.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private className: string = this.constructor.name;
    private sub: any;

    constructor(
        private injector: Injector,
        public httpError: HandleErrorService,
        protected dataService: DataService,
        private auth: AuthService,
        protected debug: DebugService,
    ) {
        this.debug.show(this.className, 'Iniciado...');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.debug.show(this.className + '::intercept::param(request)', request);

        const activeToken = this.auth.getActiveToken();

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        if (!request.headers.has('Client-Version')) {
            request = request.clone({ headers: request.headers.set('Client-Version', environment.hungryVersion.toString()) });
        }

        if (activeToken == null || activeToken == 'null') {
            this.debug.show(this.className + '::intercept', 'Não há token ativo para anexar ao header!');
        } else {
            request = request.clone({
                headers: request.headers.set("Client-Token", "Bearer " + activeToken)
            });

            this.debug.show(this.className + '::intercept', 'Token ativo anexado ao header!');
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.debug.show(this.className + '::intercept::HttpResponse', event, true);
                }
                return event;
            }),
            catchError(
                (error: HttpErrorResponse) => {
                    this.debug.show(this.className + '::intercept::HttpErrorResponse (responseStatus: ' + error.status + ')', error, false);
                    if (error.status == 401) {

                        let isLoggedIn = this.auth.isLoggedIn();
                        this.debug.show(this.className + '::intercept::HttpErrorResponse (Login Status:)', isLoggedIn);

                        if (isLoggedIn || this.auth.allowPublicMode === true) {

                            this.auth.setActiveToken(null);

                            const http = this.injector.get(HttpClient);
                            return http.post(environment.hungryApiUrl + ((isLoggedIn) ? "token/" : "publicapi/"), this.auth.bodyAuth).pipe(
                                mergeMap((response) => {
                                    this.refreshToken(response);

                                    request = request.clone({
                                        headers: request.headers.set("Client-Token", "Bearer " + activeToken)
                                    });

                                    if (!request.headers.has('Client-Version')) {
                                        request = request.clone({ headers: request.headers.set('Client-Version', environment.hungryVersion.toString()) });
                                    }

                                    // Do the request again with the new token.
                                    return next.handle(request);
                                }));

                        }
                    }

                    return Observable.throw(error);
                }
            )
        );
    }

    refreshToken(resp) {

        let data = this.dataService.extract(resp);
        if ('token' in data) {
            this.auth.setActiveToken(data['token']);
        }

    }

}
