/*
 * Inspinia js helpers:
 *
 * correctHeight() - fix the height of main wrapper
 * detectBody() - detect windows size
 * smoothlyMenu() - add smooth fade in/out on navigation show/ide
 *
 */

declare var jQuery: any;

export function correctHeight() {

  var heightWithoutNavbar = jQuery("body > #wrapper").height() - 62;
  jQuery(".sidebar-panel").css("min-height", heightWithoutNavbar + "px");

  var navbarheight = jQuery('nav.navbar-default').height();
  var wrapperHeight = jQuery('#page-wrapper').height();

  if (navbarheight > wrapperHeight) {
    jQuery('#page-wrapper').css("min-height", navbarheight + "px");
  }

  if (navbarheight < wrapperHeight) {
    jQuery('#page-wrapper').css("min-height", jQuery(window).height() + "px");
  }

  if (jQuery('body').hasClass('fixed-nav')) {
    if (navbarheight > wrapperHeight) {
      jQuery('#page-wrapper').css("min-height", navbarheight + "px");
    } else {
      jQuery('#page-wrapper').css("min-height", jQuery(window).height() - 60 + "px");
    }
  }
  // var pageWrapper = jQuery('#page-wrapper');
  // var navbarHeight = jQuery('nav.navbar-default').height();
  // var wrapperHeight = pageWrapper.height();

  // if (navbarHeight > wrapperHeight) {
  //   pageWrapper.css("min-height", navbarHeight + "px");
  // }

  // if (navbarHeight <= wrapperHeight) {
  //   if (navbarHeight < jQuery(window).height()) {
  //     pageWrapper.css("min-height", jQuery(window).height() + "px");
  //   } else {
  //     pageWrapper.css("min-height", navbarHeight + "px");
  //   }
  // }

  // if (jQuery('body').hasClass('fixed-nav')) {
  //   if (navbarHeight > wrapperHeight) {
  //     pageWrapper.css("min-height", navbarHeight + "px");
  //   } else {
  //     pageWrapper.css("min-height", jQuery(window).height() - 60 + "px");
  //   }
  // }
}

export function detectBody() {
  if (jQuery(document).width() < 769) {
    jQuery('body').addClass('body-small')
  } else {
    jQuery('body').removeClass('body-small')
  }
}

// Local Storage functions
// Set proper body class and plugins based on user configuration
jQuery(document).ready(function () {
  if (localStorageSupport()) {

    var collapse = localStorage.getItem("collapse_menu");
    var fixedsidebar = localStorage.getItem("fixedsidebar");
    var fixednavbar = localStorage.getItem("fixednavbar");
    var boxedlayout = localStorage.getItem("boxedlayout");
    var fixedfooter = localStorage.getItem("fixedfooter");

    var body = jQuery('body');

    if (fixedsidebar == 'on') {
      body.addClass('fixed-sidebar');
      jQuery('.sidebar-collapse').slimScroll({
        height: '100%',
        railOpacity: 0.9
      });
    }

    if (collapse == 'on') {
      if (body.hasClass('fixed-sidebar')) {
        if (!body.hasClass('body-small')) {
          body.addClass('mini-navbar');
        }
      } else {
        if (!body.hasClass('body-small')) {
          body.addClass('mini-navbar');
        }

      }
    }

    if (fixednavbar == 'on') {
      jQuery(".navbar-static-top").removeClass('navbar-static-top').addClass('navbar-fixed-top');
      body.addClass('fixed-nav');
    }

    if (boxedlayout == 'on') {
      body.addClass('boxed-layout');
    }

    if (fixedfooter == 'on') {
      jQuery(".footer").addClass('fixed');
    }
  }
});

// check if browser support HTML5 local storage
function localStorageSupport() {
  return (('localStorage' in window) && window['localStorage'] !== null)
}

export function smoothlyMenu() {
  if (!jQuery('body').hasClass('mini-navbar') || jQuery('body').hasClass('body-small')) {
    // Hide menu in order to smoothly turn on when maximize menu
    jQuery('#side-menu').hide();
    // For smoothly turn on menu
    setTimeout(
      function () {
        jQuery('#side-menu').fadeIn(400);
      }, 200);
  } else if (jQuery('body').hasClass('fixed-sidebar')) {
    jQuery('#side-menu').hide();
    setTimeout(
      function () {
        jQuery('#side-menu').fadeIn(400);
      }, 100);
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    jQuery('#side-menu').removeAttr('style');
  }
}
