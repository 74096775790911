import { Component, OnDestroy, OnInit, } from '@angular/core';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { Observable } from 'rxjs';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { UserService } from 'app/services/user/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { OrderService } from 'app/services/order/order.service';

@Component({
  selector: 'app-finished-orders',
  templateUrl: './finished-orders.component.html',
  styleUrls: ['./finished-orders.component.css']
})
export class FinishedOrdersComponent implements OnDestroy, OnInit {
  private className: string = this.constructor.name;

  public nav: any;
  public tokenResp$: Observable<Object>;
  public userResume$: Observable<Object>;
  public openOrders: Object | null = null;
  public updatingData: boolean = false;

  constructor(
    private auth: AuthService,
    protected afAuth: AngularFireAuth,
    public userService: UserService,
    public orderService: OrderService,
    private debug: DebugService,
  ) {
    this.debug.show(this.className, 'Iniciado...');
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";
    this.getOpenOrders();
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
  }

  
  /**
   * getOpenOrders
   */
  public getOpenOrders() {
    this.debug.show(this.className + '::getOpenOrders()');

    this.afAuth.authState.pipe(
      take(1)
    ).subscribe(user => {
      this.debug.show(this.className + '::getOpenOrders::authState>listening', user);

      if (user && !this.auth.waitNewUserData) {

        this.updateOrderData(user.uid);
        this.updatingData = true;

      }
    });

  }

  public valor: number = 0;

  /**
   * startUpdateOrderData
   */
  public updateOrderData(uid: string) {
    this.orderService.getAllOpenOrders(uid, 'F').pipe(
      take(1)
    ).subscribe(result => {
      this.updatingData = false;
      this.openOrders = result;
    });
  }

  /**
   * grandTotal
   */
  public grandTotal(currOrder) {
    return parseFloat(currOrder['pdc_total_itens']) + parseFloat(currOrder['pdc_total_taxa_servico']) + parseFloat(currOrder['pdc_total_desconto']) + parseFloat(currOrder['pdc_total_ajuste']);
  }

  public orderDetails: object | null = null;
  public showViewOrderDetails: boolean = false;
  public loadingDetails: number | null = null;

  /**
   * loadOrderDetails
   */
  public loadOrderDetails(pdc_id) {
    this.debug.show(this.className + '::loadOrderDetails()', pdc_id);

    if (this.orderDetails && this.orderDetails['pdc_id'] && pdc_id == this.orderDetails['pdc_id'] && this.showViewOrderDetails) {
      this.orderDetails = null;
      this.showViewOrderDetails = false;
    } else {
      this.loadingDetails = pdc_id;
      this.orderDetails = null;

      this.afAuth.authState.pipe(
        take(1)
      ).subscribe(user => {
        this.debug.show(this.className + '::loadOrderDetails::authState>listening', user);

        if (user && !this.auth.waitNewUserData) {

          this.orderService.getUserOrderDetais(user.uid, pdc_id).pipe(
            take(1)
          ).subscribe(result => {
            this.orderDetails = result;
            this.orderDetails['showHistoric'] = false;
            this.showViewOrderDetails = true;
            this.loadingDetails = null;
          });

        }
      });
    }
  }

  /**
   * toogleOrderHistoric
   */
  public toogleOrderHistoric() {
    if(this.orderDetails['showHistoric'] == true){
      this.orderDetails['showHistoric'] = false;
    } else {
      this.orderDetails['showHistoric'] = true;
    }
  }

  /**
   * showOrderDetails
   */
  public showOrderDetails(pdc_id) {
    return (this.orderDetails && this.orderDetails['pdc_id'] && pdc_id == this.orderDetails['pdc_id'])
  }



}
