import { Component, OnInit, OnDestroy } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
// import { Location } from "@angular/common";
// import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
// import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
// import { take } from 'rxjs/operators';
// import { StoreService } from 'app/services/store/store.service';
// import { Subscription } from 'rxjs';
// import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
// import { SubSink } from 'subsink';

// declare var $: any;

@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy {
  private className: string = this.constructor.name;

  // paymentType = null;
  // subDeliveryType: Subscription = null;
  // subPaymentType: Subscription = null;

  // private sub: any;

  constructor(
    public userService: UserService,
    // public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    // public auth: AuthService,
    // private activeRoute: ActivatedRoute,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
    // this.sub = this.activeRoute.parent.params.subscribe(params => {
    //   this.shoppingService.selectStore(params['storeNickname']);
    // });
  }

  // openActionModal(content) {
  //   this.modalShoppingService.showActionModal(content);
  // }

  // selectPaymentType(e) {
  //   if (this.paymentType !== null) {
  //     this.paymentType = !this.paymentType
  //   } else {
  //     this.paymentType = (e.target.value == "D")
  //   }

  //   if (!this.paymentType) {
  //     this.shoppingService.currOrder['payment_type'] = 'C';
  //   } else {
  //     this.shoppingService.currOrder['payment_type'] = 'D';
  //   }
  // }

  // confirmOrder() {
  //   if (this.auth.isLoggedIn) {
  //     this.shoppingService.confirmOrder();
  //   } else {
  //     this.modalShoppingService.showActionModal();
  //   }
  // }

  ngOnDestroy() {
    this.debug.show(this.className, 'ngOnDestroy');
    // this.sub.unsubscribe();
  }

}
