import { Component } from '@angular/core';
import { DataService } from 'app/services/shared/data/data.service';

@Component({
  selector: 'footer',
  templateUrl: 'footer.template.html'
})
export class FooterComponent {

  constructor(public data: DataService) {
		
  }
  
 }
