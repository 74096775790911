import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OpenOrdersComponent } from './open-orders/open-orders.component';
import { FinishedOrdersComponent } from './finished-orders/finished-orders.component';
import { ConfigService } from '../../../services/shared/config/config.service';

@NgModule({
  imports: [
    BrowserModule
  ],
  declarations: [OpenOrdersComponent, FinishedOrdersComponent],
  providers:[ConfigService]
})
export class OrdersModule { }
