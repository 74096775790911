import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import * as moment from "moment";

import * as firebase from 'firebase/app';

import { FirebaseUser } from "./firebase-user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";

import { HandleErrorService } from '../error/handle-error.service';
import { TokenService } from './token.service';
import { DataService } from '../data/data.service';
import { DebugService } from '../debug/debug.service';
import { take, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends TokenService {
  private className: string = this.constructor.name;

  public userActive$: Observable<FirebaseUser>;
  public waitNewUserData: boolean = false;

  constructor(
    private toastr: ToastrService,
    // public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected dataService: DataService,
    protected debug: DebugService
  ) {
    // super precisa estar sempre em primeiro lugar no construtor da class devido ao extends
    super(http, httpError, dataService, debug, afAuth);

    this.debug.show(this.className, 'Iniciado...');

    this.userActive$ = of<FirebaseUser>(null);

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (!this.waitNewUserData) {
        this.debug.show(this.className + '::authState>listening', user);
        this.SetUserData(user);
      }
    })
  }

  activePublicToken() {

    const activeToken = this.getActiveToken();

    if (activeToken == null || activeToken == 'null') {
      this.getNewToken()
        .pipe(
          take(1)
        ).subscribe(resp => {
          this.debug.show(this.className + '::activePublicToken::getNewToken>listening', resp);

          if ('token' in resp) {
            this.setActiveToken(resp['token']);
            // this.router.navigate(['resume']);

          } else {
            this.SignOut();
            alert('Há algum problema com a geração de token.')
          }
        });
    }
  }

  // Sign in with email/password
  SignIn(email, password, gotoRoute = null) {
    this.debug.show(this.className + '::SignIn', 'Logando...');

    this.SetUserData(null);
    this.setActiveToken(null);

    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user);
        this.ngZone.run(() => {
          this.getNewToken()
            .pipe(
              take(1)
            ).subscribe(resp => {
              if ('token' in resp) {
                this.setActiveToken(resp['token']);
                if (gotoRoute !== null) {
                  this.router.navigate(gotoRoute);
                }

              } else {
                this.SignOut();
                alert('Há algum problema com a geração de token.')
              }
            });
        });
        return true;
      }).catch((error) => {
        this.showMessagesError(error);
        return false;
      })
  }

  // Sign up with email/password
  SignUp(email, password) {
    this.debug.show(this.className + '::SignUp', 'Criando novo login...');

    this.waitNewUserData = true;
    this.SetUserData(null);

    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();

        this.ngZone.run(() => {
          this.getNewToken()
            .pipe(
              take(1)
            ).subscribe(resp => {
              if ('token' in resp) {
                this.setActiveToken(resp['token']);
              } else {
                this.SignOut();
                alert('Há algum problema com a geração de token.')
              }
            });
        });


        return result.user;
      }).catch((error) => {
        this.showMessagesError(error);
        this.waitNewUserData = false;
        return false;
      })
  }

  showMessagesError(error: any) {
    let message = "";


    switch (error.code) {
      case "auth/app-deleted":
        message = "Ops! Há algo estranho acontecendo com o servidor, tente sair e entrar novamente.";
        break;

      case "auth/app-not-authorized":
        message = "Ops! Há algo de errado com a chave do Firebase.";
        break;

      case "auth/argument-error":
        message = "Estão faltando argumento para o método chamado.";
        break;

      case "auth/invalid-api-key":
        message = "Ops! A chave da API parece estar errada.";
        break;

      case "auth/invalid-user-token":
        message = "Sua token é inválido, faça login novamente.";
        break;

      case "auth/network-request-failed":
        message = "Huuum! A rede parece instável, verifique sua conexão ou tente novamente em instantes.";
        break;

      case "auth/operation-not-allowed":
        message = "Ops! Operação inválida.";
        break;

      case "auth/too-many-requests":
        message = "Usuário bloqueado temporariamente devido muitas tentativas de acesso. Tente novamente em alguns minutos.";
        break;

      case "auth/unauthorized-domain":
        message = "Ops! Este domínio não está autorizado.";
        break;

      case "auth/user-disabled":
        message = "Huuum! Seu usuáario foi desabilitado pelo administrador. Entre em contato para verificar o motivo.";
        break;

      case "auth/user-token-expired":
        message = "Ops! Parece que suas credenciais expiraram, faça login novamente.";
        break;

      case "auth/expired-action-code":
        message = "Ops! Este código já expirou!";
        break;

      case "auth/invalid-action-code":
        message = "Hum! Este código não é válido!";
        break;

      case "auth/user-not-found":
        message = "Não há nenhuma conta no Humgry Delivey com este usuário.";
        break;

      case "auth/invalid-email":
        message = "O endereço de e-mail informado não é válido.";
        break;

      case "auth/wrong-password":
        message = "Usuário ou senha estão errados, por favor verifique.";
        break;

      case "auth/email-already-in-use":
        message = "Este endereço de e-mail já está vinculado a uma conta no Hungry Delivery";
        break;

      case "auth/weak-password":
        message = "Sua senha é muito fácil, use a criatividade, escolha uma mais difícil.";
        break;

      case "auth/timeout":
        message = "O servidor demorou muito para responder, tente novamente em alguns instantes.";
        break;

      default:
        message = error.message;
        break;
    }

    if (message !== '') {
      this.toastr.show(message, "Hungry Delivery", {
        enableHtml: true,
        closeButton: true,
        disableTimeOut: 'timeOut',
        positionClass: 'toast-center-center',
        toastClass: 'ngx-toastr bg-red-to-orange-230 toaster-install'
      });
      // .onTap.subscribe(() => {});
    }
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        // this.router.navigate(['verify-email-address']);
      })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.toastr.show("Enviamos para o seu e-mail o link para a redefinição da sua senha.", "Hungry Delivery", {
          enableHtml: true,
          closeButton: true,
          timeOut: 10000,
          disableTimeOut: 'timeOut',
          positionClass: 'toast-center-center',
          toastClass: 'ngx-toastr bg-red-to-orange-230 toaster-install'
        });
      }).catch((error) => {
        this.showMessagesError(error);
      })
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['resume']);
        })
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    this.debug.show(this.className + '::SetUserData::Param(user)', user);

    if (user) {
      // this.userData = user;

      let userData: FirebaseUser = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        emailVerified: user.emailVerified
      }

      this.userActive$ = of<FirebaseUser>(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      // JSON.parse(localStorage.getItem('user'));
    } else {
      this.userActive$ = of<FirebaseUser>(null);
      localStorage.setItem('user', null);
      // JSON.parse(localStorage.getItem('user'));
    }
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    // const userData: FirebaseUser = {
    //   uid: user.uid,
    //   email: user.email,
    //   displayName: user.displayName,
    //   photoURL: user.photoURL,
    //   emailVerified: user.emailVerified
    // }
    // return userRef.set(userData, {
    //   merge: true
    // })
  }

  // Sign out 
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      this.setActiveToken(null);
      this.userActive$ = null;
      localStorage.removeItem('user');
      this.router.navigate(['/login']);
    })
  }

  SignOutAndStay(currRoute = '/') {
    return this.afAuth.auth.signOut().then(() => {
      this.setActiveToken(null);
      this.userActive$ = null;
      localStorage.removeItem('user');
      this.router.navigate([currRoute]);
    });
  }

  //   user: Observable<FirebaseUser | null>;
  //   private webServiceURL = null;
  //   private publicAuthKey = null;
  //   static changedStoreId = new EventEmitter<string>(true);
  //   private currentStoreId = null;
  //   private currentStoreName = '';

  //   constructor(
  //     private http: HttpClient,
  //     private afAuth: AngularFireAuth,
  //     private router: Router,
  //     private notify: NotifyService,
  //     private permission: PermissionService
  //   ) {
  //     this.loadWebServiceHost();
  //     this.loadPublicAuthKey();
  //     this.listenAuthState();
  //   }

  //   /**
  //  * @description Efetua login do usuário no sistema
  //  * @param email Endereço de e-mail usado para login
  //  * @param password Senha do usuário
  //  */
  //   emailLogin(email: string, password: string) {
  //     return this.afAuth.auth.signInWithEmailAndPassword(email, password)
  //       .then((user) => {
  //         void this.loadStoreId(user);
  //         void this.loadProfileId(user);
  //         this.notify.update('Login realizado com sucesso!!!', 'success')
  //       })
  //       .catch((error) => this.handleError(error));
  //   }

  //   /**
  //    * @description Requisita ao webservice o JWT Token para iniciar o sistema
  //    * @returns void
  //    */
  //   private getAuthToken(user: any) {
  //     let pAuthKey = this.publicAuthKey;
  //     let userUid = user.uid;
  //     let urlBase = this.getWebServiceHost();
  //     console.log('teste getAuthToken');
  //     console.log("pAuthKey: " + pAuthKey);
  //     console.log("userUid: " + userUid);
  //     console.log("urlBase: " + urlBase)
  //     let body = {
  //       "service": "api",
  //       "method": "generatorToken",
  //       "param": {
  //         "publicAuthKey": pAuthKey,
  //         "userUid": userUid
  //       }
  //     }
  //     return this.http.post(urlBase, body);
  //     // .map((data) => console.log(JSON.stringify(data)))
  //     // .do((response) => console.log(JSON.stringify(response)))
  //     // .catch(this.handleErrorObs);
  //   }

  //   private handleErrorObs(error) {
  //     console.error(JSON.stringify(error));
  //     return Observable.throw(JSON.stringify(error) || 'Server error');
  //   }

  //   private setSession(authResult) {
  //     console.log("teste 1");
  //     console.log(authResult);
  //     // const expiresAt = moment().add(authResult.expiresIn,'second');

  //     // localStorage.setItem('id_token', authResult.idToken);
  //     // localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  //   }

  //   /**
  //    * @description Verificação do status da autenticação, se ocorrer alguma alteração no status (login/logout) 
  //    * a função atualiza a variável this.user<Observable> e lê a store vinculada ao usuário
  //    * para listar apenas os dados da referida store.
  //    * @returns void
  //    */
  //   private listenAuthState() {
  //     // this.user = this.afAuth.authState.switchMap((user) => {
  //     //   if (user) {
  //     //     void this.loadStoreId(user);
  //     //     void this.loadProfileId(user);

  //     //     this.getAuthToken(user).subscribe((data) => console.log(JSON.stringify(data)),
  //     //       error => console.log(error));

  //     //     return Observable.of(user);
  //     //   } else {
  //     //     this.currentStoreId = null;
  //     //     this.currentStoreName = '';
  //     //     AuthService.changedStoreId.emit(null);
  //     //     return Observable.of(null);
  //     //   }
  //     // });
  //   }

  //   /**
  //  * @description Retorna o Uid vinculado ao usuário logado no sistema
  //  * @returns Uid
  //  */
  //   public getCurrenUserUid() {
  //     return this.afAuth.auth.currentUser.uid;
  //   }
  //   /**
  //    * @description Retorna o storeId vinculado ao usuário logado no sistema
  //    * @returns storeId
  //    */
  //   public getCurrentStoreId() {
  //     return this.currentStoreId;
  //   }

  //   /**
  //    * @description Retorna o storeId vinculado ao usuário logado no sistema
  //    * @returns storeId
  //    */
  //   public getCurrentStoreName() {
  //     return this.currentStoreName;
  //   }

  //   /**
  //    * @description Atualiza o storeId usado pelo sistema para listar dados
  //    * @param storeId Novo storeId a ser usado pelo sistema para listar dados
  //    * @returns void
  //    */
  //   public setCurrentStore(storeId, storeName) {
  //     this.updateUserStore(storeId, storeName);
  //   }

  //   /**
  //    * @description Atualiza o storeId na a aplicação para listar os respectivos dados em tempo de execução
  //    * @param storeId Novo storeId a ser usado pelo sistema para listar dados
  //    * @param storeName Novo storeName(Nome Fantasia) a ser vinculado ao usuário
  //    * @returns void
  //    */
  //   private updateCurrentStore(storeData) {
  //     if (this.getCurrentStoreId() !== storeData['str_id']) {
  //       this.currentStoreId = storeData['str_id'];
  //       this.currentStoreName = storeData['cpn_fantasy_name'];
  //       AuthService.changedStoreId.emit(this.currentStoreId);
  //     }
  //   }

  //   /**
  //    * @description Atualiza o storeId vinculado ao usuário logado no sistema que pode ser escutado por
  //    * qualquer componente dependente deste no sistema
  //    * @param storeId Novo storeId a ser vinculado ao usuário
  //    * @param storeName Novo storeName(Nome Fantasia) a ser vinculado ao usuário
  //    * @returns void
  //    */
  //   private updateUserStore(storeId, storeName) {
  //     var user = this.afAuth.auth.currentUser;
  //     firebase.database().ref('users/' + user.uid + '/currStore')
  //       .update({ str_id: storeId, cpn_fantasy_name: storeName });
  //   }

  //   /**
  //    * @description Busca no banco de dados do firebase o storeId vinculado ao usuário logado
  //    * e executa this.updateCurrentStore(storeData) para atualizar os dados exibidos em tempo de execução
  //    * @returns void
  //    */
  //   private loadStoreId(actUser) {
  //     var user = null;
  //     if (actUser !== undefined) {
  //       user = actUser;
  //     } else {
  //       user = this.afAuth.auth.currentUser;
  //     }
  //     firebase.database().ref('users/' + user.uid + '/currStore')
  //       .on('value',
  //         snapshot => {
  //           this.updateCurrentStore(snapshot.val())
  //         }
  //       );
  //   }

  //   /**
  //    * @description Busca no banco de dados do firebase o profileId vinculado ao usuário logado
  //    * e executa this.setCurrentStoreId(profileId) para atualizar os dados exibidos em tempo de execução
  //    * @returns void
  //    */
  //   private loadProfileId(actUser) {
  //     var user = null;
  //     if (actUser !== undefined) {
  //       user = actUser;
  //     } else {
  //       user = this.afAuth.auth.currentUser;
  //     }
  //     firebase.database().ref('users/' + user.uid + '/profile')
  //       .on('value',
  //         snapshot => {
  //           this.permission.setCurrentProfile(snapshot.val())
  //         }
  //       );
  //   }

  /**
   * @description Indica se a permissão requerida está contida na lista de permissões do perfil do 
   * usuário logado.
   * @returns boolean
   */
  public havePermission(permissionCod) {
    // if (!this.permission.havePermission(permissionCod)) {
    //   //   this.router.navigate(['/starterview']);
    //   return false;
    // } else {
    return true;
    // }
  }

  //   /**
  //    * @description Lê o endereço do webservice para a aplicação
  //    * @returns void
  //    */
  //   private loadWebServiceHost() {

  //     /** Remover isso e implementar para que o sistema espere o carregamento do link antes de continuar. */
  //     this.webServiceURL = "http://localhost/hungry-webservice/";

  //     firebase.database().ref('system/webServiceHost')
  //       .on('value',
  //         snapshot => {
  //           this.webServiceURL = snapshot.val(),
  //             console.log('Web Service Host: ' + snapshot.val())
  //         }
  //       );
  //   }

  //   /**
  //    * @description Retorna a url do Web Service para onde as requisições devem ser enviadas
  //    * @returns string Domínio ou ip onde o webservice está ativo
  //    */
  //   public getWebServiceHost() {
  //     return this.webServiceURL;
  //   }

  //   /**
  //    * @description Lê a publicAuthKey para após a autenticação no firebase requisitar o 
  //    * JWT Token do lado do servidor
  //    * @returns void
  //    */
  //   private loadPublicAuthKey() {

  //     /** Remover isso e implementar para que o sistema espere o carregamento da chave antes de continuar. */
  //     this.publicAuthKey = "tywm778gd9s7k8i2n6f47td8jfq94iw7h6nbwj45t";

  //     firebase.database().ref('system/publicAuthKey')
  //       .on('value',
  //         snapshot => {
  //           this.publicAuthKey = snapshot.val(),
  //             console.log('publicAuthKey: ' + snapshot.val())
  //         }
  //       );
  //   }

  //   /**
  //    * @description Cria um novo usuáro no firebase usando e-mail e senha como credencias
  //    * @param email Endereço de e-mail do usuário
  //    * @param password Senha do usuário
  //    */
  //   emailSignUp(email: string, password: string) {
  //     return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
  //       .then((user) => {
  //         this.notify.update('Cadastro realizado com sucesso!!!', 'success'),
  //           firebase.database().ref('users/' + user["uid"] + '/currStore/').update({ str_id: this.currentStoreId, cpn_fantasy_name: this.currentStoreName })
  //       })
  //       .catch((error) => this.handleError(error));
  //   }

  //   /**
  //    * @description Envia email de redefinição de senha para o usuário
  //    * @param email Endereço de e-mail do usuário usado para login
  //    */
  //   resetPassword(email: string) {
  //     return this.afAuth.auth.sendPasswordResetEmail(email)
  //       .then(() => this.notify.update('E-mail de atualização de senha enviado', 'info'))
  //       .catch((error) => this.handleError(error));
  //   }

  //   /**
  //    * Efetua logout do sistema e encerra sessão do firebase
  //    */
  //   signOut() {
  //     this.afAuth.auth.signOut().then(() => {
  //       this.router.navigate(['/']);
  //     });
  //   }

  //   /**
  //    * Tratamento de erros gerando notificação do sistema
  //    * @param error 
  //    */
  //   private handleError(error: Error) {
  //     this.notify.update(error.message, 'error');
  // }

}
