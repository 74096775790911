import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { NotifyService } from '../../services/shared/notify/notify.service';
import { AuthService } from '../../services/shared/auth/auth.service';

import { UserLogin } from '../../services/user/user.class';
import { DataService } from 'app/services/shared/data/data.service';

declare var jQuery: any;

@Component({
  selector: 'login',
  templateUrl: './login.template.html'
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  user: UserLogin;

  constructor(
    private router: Router,
    private auth: AuthService,
    public notify: NotifyService,
    public data: DataService,
    private formB: FormBuilder
  ) {
    this.createForm();
  }

  private createForm() {
    this.loginForm = this.formB.group({
      usr_email: ['', [Validators.required, Validators.email]],
      usr_password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  public emailLogin() {
    this.user = this.prepareSaveLogin();
    this.auth.SignIn(this.user.usr_email, this.user.usr_password, ['resume'])
  }

  prepareSaveLogin(): UserLogin {
    const formModel = this.loginForm.value;
    const saveLogin: UserLogin = {
      usr_email: formModel.usr_email as string,
      usr_password: formModel.usr_password as string,
    };
    return saveLogin;
  }

  private afterSignIn() {
    // Do after login stuff here, such router redirects, toast messages, etc.
    // this.router.navigate(['/resume']);
  }

  signOut() {
    this.auth.SignOut();
  }

  public ngOnInit(): any {
    jQuery("body").addClass("bglogin");
  }

  public ngOnDestroy(): any {
    jQuery("body").removeClass("bglogin");
  }

}
