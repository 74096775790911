import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, retry, take, catchError } from 'rxjs/operators';

import { DataService } from '../shared/data/data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HandleErrorService } from '../shared/error/handle-error.service';
import { DebugService } from '../shared/debug/debug.service';
import { AuthService } from '../shared/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private className: string = this.constructor.name;

  constructor(
    private authService: AuthService,
    protected afAuth: AngularFireAuth,
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected dataService: DataService,
    protected debug: DebugService
  ) {

    this.debug.show(this.className, 'Iniciado...');

  }

  /**
   * getAllOpenOrders
   */
  public getAllOpenOrders(uid: string, status: string = null) {

    let currStatus = {};
    if(status){
      currStatus = { 'order_status': status };
    }

    if (uid) {
      return this.http.get(environment.hungryApiUrl + "order/" + uid, { params: currStatus })
        .pipe(
          map(resp => (this.dataService.extract(resp))),
          take(1),
          retry(2),
          catchError(this.httpError.handleHttpError)
        );
    } else {
      return of(null);
    }

  }

  leadingZeros(value: number | string, endSize: number = 5) {
    return Array(endSize + 1 - value.toString().length).join("0") + value.toString();
  }

  formatDate(date) {
    date = date.toString();
    return date.substr(8, 2) + '/' + date.substr(5, 2) + '/' + date.substr(0, 4) + ' as ' + date.substr(11, 5);
  }

  getUserOrderDetais(uid, pdc_id) {
    return this.http.get(environment.hungryApiUrl + "order/" + uid, {params: { 'order_id': pdc_id }})
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

}
