// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   showDebug: true,
//   logGenerate: false,
//   logSingleFile: true,
//   logFail: true,
//   logExecution: true,
//   logSuccess: true,
//   hungryVersion: 1,
//   hungryApiUrl: "http://10.0.0.105/hungry-webservice/"
// };

// export const environment = {
//   production: false,
//   showDebug: false,
//   logGenerate: false,
//   logSingleFile: true,
//   logFail: true,
//   logExecution: true,
//   logSuccess: true,
//   hungryVersion: 1,
//   hungryApiUrl: "https://sandboxapi.hungrydelivery.club/v1/"
// };

export const environment = {
  production: false,
  showDebug: false,
  logGenerate: false,
  logSingleFile: true,
  logFail: true,
  logExecution: true,
  logSuccess: true,
  hungryVersion: 1,
  hungryApiUrl: "https://demoapi.hungrydelivery.club/v1/"
};
