import { Component } from '@angular/core';
import { smoothlyMenu } from 'app/app.helpers';
import { Router } from '@angular/router';

import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { DebugService } from 'app/services/shared/debug/debug.service';

declare var $: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent {

  constructor(
    public auth: AuthService,
    public userService: UserService,
    public router: Router,
    private debug: DebugService) {
  }

  ngAfterViewInit() {
    //$("body").addClass("fixed-nav");
  }

  toggleNavigation(): void {
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  signOut() {
    this.auth.SignOut();
  }

  goToStores() {
    this.router.navigate(['/stores']);
  }

}
