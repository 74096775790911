import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, tap, retry, take, catchError } from 'rxjs/operators';

import { Address } from 'app/services/interface/address';
import { DataService } from '../shared/data/data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HandleErrorService } from '../shared/error/handle-error.service';
import { DebugService } from '../shared/debug/debug.service';
import { AuthService } from '../shared/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private className: string = this.constructor.name; 

  constructor(
    private authService: AuthService,
    protected afAuth: AngularFireAuth,
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected dataService: DataService,
    protected debug: DebugService) {

    this.debug.show(this.className, 'Iniciado...');

  }

  /**
   * getStates
   */
  public getStates(): Observable<Object> {
    this.debug.show(this.className + '::getStates');

    return this.http.get(environment.hungryApiUrl + "address/states")
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  /**
   * getCities
   */
  public getCities(state: string): Observable<Object> {
    this.debug.show(this.className + '::getCities::param(state)', state);

    return this.http.get(environment.hungryApiUrl + "address/cities/" + state)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  /**
   * getDistricts
   */
  public getDistricts(cdd_id: number): Observable<Object> {
    this.debug.show(this.className + '::getDistricts::param(cdd_id)', cdd_id);

    return this.http.get(environment.hungryApiUrl + "address/districts/" + cdd_id)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

}
