import { Component, AfterViewInit, NgZone } from '@angular/core';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DataService } from 'app/services/shared/data/data.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'app/services/user/user.service';
import { StoreService } from 'app/services/store/store.service';
import { Address } from 'app/services/interface/address';

import { Subscription } from 'rxjs';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'modal-content-address-data',
  templateUrl: './modal-content-address-data.component.html',
  styleUrls: ['./modal-content-address-data.component.css']
})
export class ModalContentAddressDataComponent implements AfterViewInit {
  private className: string = this.constructor.name;

  addressCEP: string = "";
  addressState: string = "";
  addressCity: string = "";
  addressCityName: string = "";
  addressDistrict: string = "";
  addressDistrictName: string = "";
  addressStreet: string = "";
  addressNumber: string = "";
  addressComplement: string = "";
  addressMain: number = 0;

  constructor(
    protected afAuth: AngularFireAuth,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public dataService: DataService,
    private auth: AuthService,
    private shoppingService: ShoppingService,
    private userService: UserService,
    private storeService: StoreService,
    public modalShoppingService: ModalShoppingService,
    private debug: DebugService,
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngAfterViewInit() {
    if (this.modalShoppingService.contentData) {
      this.setAddressData();
    }
    this.getReachStates();
  }

  previous() {
    this.modalShoppingService.showActionModal('address');
  }

  setAddressData() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    // const inputState: any = document.getElementById('usr_state');
    // const inputCity: any = document.getElementById('usr_city');
    // const inputDistrict: any = document.getElementById('usr_district');
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    const inputAddressNumber: any = document.getElementById('usr_address_number');
    const inputAddressComplement: any = document.getElementById('usr_address_complement');

    inputAddressCEP.value = this.modalShoppingService.contentData['end_cep'];
    // this.addressState = this.modalShoppingService.contentData[''];
    // this.addressCity = this.modalShoppingService.contentData[''];
    // this.addressDistrict = this.modalShoppingService.contentData[''];
    inputAddressStreet.value = this.modalShoppingService.contentData['end_logradouro'];
    inputAddressNumber.value = this.modalShoppingService.contentData['end_numero'];
    inputAddressComplement.value = this.modalShoppingService.contentData['end_complemento'];

    this.CEPMask();
  }

  checkRequiredStepFields() {
    if (this.checkCEP() && this.checkAddressStreet() && this.checkAddressNumber() && this.checkAddressComplement()) {
      const inputAddressCEP: any = document.getElementById('usr_address_cep');
      const inputState: any = document.getElementById('usr_state');
      const inputCity: any = document.getElementById('usr_city');
      const inputDistrict: any = document.getElementById('usr_district');
      const inputAddressStreet: any = document.getElementById('usr_address_street');
      const inputAddressNumber: any = document.getElementById('usr_address_number');
      const inputAddressComplement: any = document.getElementById('usr_address_complement');
      const inputMainAddress: any = document.getElementById('etp_id');

      if (inputState.selectedIndex == -1 || inputCity.selectedIndex == -1 || inputDistrict.selectedIndex == -1)
        return false;

      this.addressCEP = inputAddressCEP.value;
      this.addressState = inputState.value;
      this.addressCity = inputCity.value;
      this.addressCityName = inputCity.options[inputCity.selectedIndex].text;
      this.addressDistrict = inputDistrict.value;
      this.addressDistrictName = inputDistrict.options[inputDistrict.selectedIndex].text;
      this.addressStreet = inputAddressStreet.value;
      this.addressNumber = inputAddressNumber.value;
      this.addressComplement = inputAddressComplement.value;
      this.addressMain = ((inputMainAddress.checked) ? 1 : 2);

      return true;
    }
    return false;
  }

  stateError: boolean = false;
  cityError: boolean = false;
  streetError: boolean = false;
  districtError: boolean = false;
  CEPError: boolean = false;
  numberError: boolean = false;
  complementError: boolean = false;

  stateSelected: boolean = false;
  citySelected: boolean = false;
  districtSelected: boolean = false;

  reachStates: Object | null = null;
  reachCities: Object | null = null;
  reachDistricts: Object | null = null;

  checkCEP() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    this.CEPError = ((inputAddressCEP.value.length === 10) ? false : true);
    return !this.CEPError;
  }

  CEPMask() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    const newValue = this.dataService.formattingCEP(inputAddressCEP.value);
    if (inputAddressCEP.value !== newValue) {
      inputAddressCEP.value = newValue;
    }
  }

  checkAddressStreet() {
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    this.streetError = (inputAddressStreet.value.length < 5 || inputAddressStreet.value.length > 255);
    return !this.streetError;
  }

  limitAddressStreet() {
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    if (inputAddressStreet.value.length > 255) {
      inputAddressStreet.value = inputAddressStreet.value.substring(0, 255);
    }
  }

  checkAddressNumber() {
    const inputAddressStreet: any = document.getElementById('usr_address_number');
    this.numberError = (inputAddressStreet.value.length > 8);
    return !this.numberError;
  }

  limitNumber() {
    const inputAddressNumber: any = document.getElementById('usr_address_number');
    if (inputAddressNumber.value.length > 8) {
      inputAddressNumber.value = inputAddressNumber.value.substring(0, 8);
    }
  }

  checkAddressComplement() {
    const inputAddressComplement: any = document.getElementById('usr_address_complement');
    this.complementError = (inputAddressComplement.value.length > 80);
    return !this.complementError;
  }

  limitComplement() {
    const inputAddressComplement: any = document.getElementById('usr_address_complement');
    if (inputAddressComplement.value.length > 80) {
      inputAddressComplement.value = inputAddressComplement.value.substring(0, 80);
    }
  }

  selectDefaultState(statetData: object) {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['uf_sigla']) {
      if (this.modalShoppingService.contentData['uf_sigla'] == statetData['uf_sigla']) {
        this.stateSelected = true;
        return true;
      } else {
        return false;
      }
    } else {
      if (this.storeService.defaultState !== null && this.storeService.defaultState == statetData['uf_sigla']) {
        this.stateSelected = true;
        return true;
      } else {
        return false;
      }
    }
  }

  getReachStates() {
    this.storeService.getReachStates(this.dataService.currStore['storeNickname'])
      .pipe(
        take(1)
      )
      .subscribe(
        states => {
          this.reachStates = states;
          this.reachCities = null;
          this.reachDistricts = null;
          this.stateSelected = false;
          this.citySelected = false;
          if (this.storeService.defaultState !== null) {
            this.getReachCities(this.storeService.defaultState);
          }
        }
      );
  }

  loadCities() {
    const inputState: any = document.getElementById('usr_state');
    this.stateSelected = true;
    this.citySelected = false;
    this.districtSelected = false;
    this.reachDistricts = null;
    this.getReachCities(inputState.value);
  }

  getReachCities(uf_sigla: string) {
    this.storeService.getReachCities(this.dataService.currStore['storeNickname'], uf_sigla)
      .pipe(
        take(1)
      )
      .subscribe(
        cities => {
          this.reachCities = cities;
          this.reachDistricts = null;
          this.citySelected = false;
          if (this.storeService.defaultCity !== null) {
            this.getReachDistricts(this.storeService.defaultCity);
          }
        }
      );
  }
  
  selectDefaultCity(cityData: object) {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['cdd_id']) {
      if (this.modalShoppingService.contentData['cdd_id'] == cityData['cdd_id']) {
        this.citySelected = true;
        return true;
      } else {
        return false;
      }
    } else {
      if (this.storeService.defaultCity !== null && this.storeService.defaultCity == cityData['cdd_id']) {
        this.citySelected = true;
        return true;
      } else {
        return false;
      }
    }
  }


  loadDistricts() {
    const inputCity: any = document.getElementById('usr_city');
    this.citySelected = true;
    this.districtSelected = false;
    this.reachDistricts = null;
    this.getReachDistricts(inputCity.value);
  }

  selectDefaultDistrict(districtData: object) {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['bro_id']) {
      if (this.modalShoppingService.contentData['bro_id'] == districtData['bro_id']) {
        this.districtSelected = true;
        return true;
      } else {
        return false;
      }
    } else {
      if (this.storeService.defaultDistrict !== null && this.storeService.defaultDistrict == districtData['bro_id']) {
        this.districtSelected = true;
        return true;
      } else {
        return false;
      }
    }
  }

  getReachDistricts(cdd_id: number) {
    this.storeService.getReachDistricts(this.dataService.currStore['storeNickname'], cdd_id)
      .pipe(
        take(1)
      )
      .subscribe(
        districts => {
          this.districtSelected = false;
          if (districts != 'No data found or disabled.') {
            this.reachDistricts = districts;
          } else {
            this.reachDistricts = null;
            alert('Entre em contato conosco e informe que seu bairro não está disponível no sistema.');
          }
        });
  }

  checkAddressType() {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['etp_id'] !== null) {
      return (parseInt(this.modalShoppingService.contentData['etp_id']) == 1);
    } else {
      return false;
    }
  }

  // -------------------------------------
  // Gravando endereço

  saveAddressData() {
    this.debug.show(this.className + "::saveAddressData()", 'Gravando endereço...');

    if (this.checkRequiredStepFields()) {

      this.modalShoppingService.isLoading = true;

      let endereco: Address = {
        end_logradouro: this.addressStreet,
        end_numero: this.addressNumber,
        end_complemento: this.addressComplement,
        end_obs: "",
        end_cep: this.addressCEP.replace(/(\s|\.|\-)/g, ''),
        bro_id: parseInt(this.addressDistrict),
        cdd_id: parseInt(this.addressCity),
        etp_id: ((this.addressMain !== 1)? 2 : 1)
      };

      if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['end_id']) {
        endereco['end_id'] = parseInt(this.modalShoppingService.contentData['end_id']);
        this.userService.updateAddress(endereco);
      } else {
        this.userService.newAddress(endereco);
      }

      this.modalShoppingService.isLoading = false;
      this.previous();

    } else {
      alert("Preencha todos os campos corretamente.");
      this.modalShoppingService.isLoading = false;
    }

  }

}
