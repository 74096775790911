import { Component, OnInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { Subscription } from 'rxjs';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';

@Component({
  selector: 'modal-cart-content-products',
  templateUrl: './modal-cart-content-products.component.html',
  styleUrls: ['./modal-cart-content-products.component.css']
})
export class ModalCartContentProductsComponent implements OnInit {
  private className: string = this.constructor.name;

  subDeliveryType: Subscription = null;

  constructor(
    public dataService: DataService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  removeOrderProduct(productIndex) {
    this.shoppingService.currOrder['products'].splice(productIndex, 1);
    this.shoppingService.calculateOrder();
  }

  editOrderProduct(product: Object, productIndex: Number) {
    this.debug.show(this.className + '::editOrderProduct::param(product)', product);

    this.shoppingService.selectProduct(product, productIndex);

    for (let subitem in product['selectedSubItems']) {
      this.shoppingService.selectSubitem(Object.assign({}, product['selectedSubItems'][subitem]));
    }

    for (let complement in product['selectedComplements']) {
      this.shoppingService.selectComplement(Object.assign({}, product['selectedComplements'][complement]));
    }

  }

}
