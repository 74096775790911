import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { DataService } from 'app/services/shared/data/data.service';
import { StoreService } from 'app/services/store/store.service';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent implements OnInit, AfterViewInit {

  private className: string = this.constructor.name;

  private swUpdate: SwUpdate;
  isInStandaloneMode: boolean = false;
  installingApp: boolean = false;
  isMobile: boolean = true;
  citiesServed: Object | null = null;
  storesCurrCity: Object | null = null;

  countOpenStores: number = 0;
  countCloseStores: number = 0;

  deferredPrompt: any;
  showButton = false;

  constructor(
    public dataService: DataService,
    public storeService: StoreService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  addToHomeScreen() {

    this.installingApp = true;
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  ngOnInit() {
    const userAgent = window.navigator.userAgent.toLowerCase();

    // Detects if device is on iOS 
    const isAndroid = (/android/.test(userAgent));

    // Detects if device is on iOS 
    const isIos = (/iphone|ipad|ipod/.test(userAgent));

    this.isInStandaloneMode = (('standalone' in (window as any).navigator) && ((window as any).navigator.standalone) || (window.matchMedia('(display-mode: standalone)').matches));

    this.isMobile = (isAndroid || isIos);

    this.getCitiesServed();
  }

  ngAfterViewInit() {
    if (!this.dataService.currSelCity) {
      this.showModalCity();
    }
  }

  showModalIphone() {
    $('#instIphone').modal('show').addClass('animated fadeInDown');
  }

  showModalAndroid() {
    $('#instAndroid').modal('show').addClass('animated fadeInDown');
  }

  showModalCity() {
    $('#selCity').modal('show').addClass('animated fadeInDown');
  }

  closeModalIphone() {
    $('#instIphone').modal('hide').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight fadeInDown');
  }

  closeModalAndroid() {
    $('#instAndroid').modal('hide').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight fadeInDown');
    this.installingApp = false;
  }

  closeModalCity() {
    $('#selCity').modal('hide').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight fadeInDown');
  }

  getCitiesServed() {
    this.storeService.getCitiesServed()
      .pipe(
        take(1)
      )
      .subscribe(
        cities => {
          this.citiesServed = cities;
          if (!this.dataService.currSelCity) {
            this.selectCity(this.storeService.defaultCity, false);
          } else {
            this.selectCity(this.dataService.currSelCity['cdd_id'], false);
          }
        }
      );
  }

  loadStores() {
    const inputCity: any = document.getElementById('usr_currcity');
    this.storeService.getAllStoresByCity(inputCity.value)
      .pipe(
        take(1)
      )
      .subscribe(
        stores => {
          this.storesCurrCity = stores;
        }
      );
  }

  getCountStores() {
    if (this.storesCurrCity) {
      for (var store in this.storesCurrCity['data']) {
        if (this.storesCurrCity['data'][store]['lj_status'] == 1) {
          this.countOpenStores++;
        } else if (this.storesCurrCity['data'][store]['lj_status'] == 0) {
          this.countCloseStores++;
        }
      }
    }
  }

  selectCity(cdd_id: number, closeModal: boolean = true) {
    this.countOpenStores = 0;
    this.countCloseStores = 0;
    this.storeService.getAllStoresByCity(cdd_id)
      .pipe(
        take(1)
      )
      .subscribe(
        stores => {
          this.storesCurrCity = stores;
          this.getCountStores();

          if (closeModal) {
            this.closeModalCity();
          }

          for (var city in this.citiesServed['data']) {
            if (!this.citiesServed['data'][city]) {
              this.dataService.currSelCity = null;
            } else {
              if (this.citiesServed['data'][city]['cdd_id'] == cdd_id) {
                this.dataService.currSelCity = this.citiesServed['data'][city];
              }
            }
          }

        }
      );
  }

}
