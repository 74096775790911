import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';

declare var $: any;

@Component({
    selector: 'complementlist',
    templateUrl: './complement-template.component.html',
  })
  
  export class ComplementListComponent implements OnInit, AfterViewInit {
  
    constructor(
      public shoppingService: ShoppingService,
      private debug: DebugService) { }
  
    ngOnInit() { }
  
    fecharModal() {
      $('#complementModal').modal('hide');
    }
  
    ngAfterViewInit() {
      this.shoppingService.getProductComplements();
    }
  
    selectComplement(complement: Object | null) {
      if (complement && this.shoppingService.acceptsMoreComplements()) {
        this.shoppingService.selectComplement(complement);
      }
      $('#complementModal').modal('hide');
    }
  
  }