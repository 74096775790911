import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { smoothlyMenu } from '../../../../app.helpers';

import { AuthService } from '../../../../services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';

declare var $: any;

@Component({
  selector: 'topshoppingnavigation',
  templateUrl: './top-shopping-navbar.component.html',
  styleUrls: ['./top-shopping-navbar.component.css']
})
export class TopShoppingNavbarComponent {

  constructor(
    public auth: AuthService,
    public dataService: DataService,
    public userService: UserService,
    public shoppingService: ShoppingService,
    public modalShoppingService: ModalShoppingService,
    private location: Location,
    private debug: DebugService) {
  }

  ngAfterViewInit() {
    // $("body").addClass("fixed-nav");
  }

  toggleNavigation(): void {
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  signOut() {
    this.auth.SignOutAndStay(this.location.path());
  }

}