import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, retry, take, catchError, first } from 'rxjs/operators';

import { HandleErrorService } from '../error/handle-error.service';
import { Observable, of } from 'rxjs';
import { DataService } from '../data/data.service';
import { DebugService } from '../debug/debug.service';
import { environment } from './../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private subClassName: string = "TokenService";

  private activeToken: string = null;
  public allowPublicMode: boolean = false;

  public bodyAuth = {
    "publicAuthKey": "Aywm778gd9s7k8i2n6f47td8jfq94iw7h6nbwj45t",
    "userUid": "YSXSun2qgfN3B1g8ii6R6fxHhL42",
    "email": "harvey@hotmail.com.br",
    "pass": "teste123"
  };

  constructor(
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected dataService: DataService,
    protected debug: DebugService,
    protected afAuth: AngularFireAuth) {
    this.debug.show(this.subClassName, 'Iniciado...');

    if (localStorage.getItem('publicToken')) {
      this.setActiveToken(localStorage.getItem('publicToken'));
    }
  }

  // // Returns true when user is looged in and email is verified
  // get isLoggedIn(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   return (user !== null) ? true : false;
  //   // return (user !== null && user.emailVerified !== false) ? true : false;
  // }

  async isLoggedIn() {
    this.debug.show(this.subClassName + '::isLoggedIn', '', true);
    return await this.afAuth.authState.pipe(first()).toPromise();
    // return this.afAuth
  }

  /**
   * getActiveToken
   */
  public getActiveToken() {
    this.debug.show(this.subClassName + '::getActiveToken', this.activeToken);
    return this.activeToken;
  }

  /**
   * setActiveToken
   */
  public setActiveToken(token) {
    // Mudar depois
    // token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDM3NTY1MDMsImlzcyI6Imh1bmdyeWRlbGl2ZXJ5LmNsdWIiLCJleHAiOjE4MDM3NjM3MDMsInVzZXJJZCI6IllTWFN1bjJxZ2ZOM0IxZzhpaTZSNmZ4SGhMNDIifQ.ip5h42-J7N3aIJDlpR4iw39Ec8chcSCyqxgaOjnRp1k';

    this.debug.show(this.subClassName + '::setActiveToken::param(token)', token);
    localStorage.setItem('publicToken', token);
    this.activeToken = token;
  }

  public getToken(): Observable<Object> {
    if (!this.activeToken) {
      this.debug.show(this.subClassName + '::getToken', 'Não existe token ativo.');
      return this.getNewToken();
    } else {
      this.debug.show(this.subClassName + '::getToken', 'Existe token ativo.');
      return of(this.activeToken);
    }
  }

  public getNewToken(): Observable<Object> {
    this.debug.show(this.subClassName + '::getNewToken');

    const user = this.isLoggedIn();
    const isLogged = ((user)? true : false);

    this.debug.show(this.subClassName + '::getNewToken->isLogged', isLogged);

    return this.http.post(environment.hungryApiUrl + ((isLogged === true) ? "token/" : "publicapi/"), this.bodyAuth)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );

  }
}
