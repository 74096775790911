import { Component, OnInit } from '@angular/core';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DataService } from 'app/services/shared/data/data.service';

@Component({
  selector: 'modal-cart-content-user',
  templateUrl: './modal-cart-content-user.component.html',
  styleUrls: ['./modal-cart-content-user.component.css']
})
export class ModalCartContentUserComponent implements OnInit {
  private className: string = this.constructor.name;

  constructor(
    private debug: DebugService,
    public shoppingService: ShoppingService,
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

}
