import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'Authorization': 'my-auth-token'
//   })
// };

@Injectable()
export class ConfigService {

  public showDebug: boolean = environment.showDebug;
  public logGenerate: boolean = environment.logGenerate;
  public logSingleFile: boolean = environment.logSingleFile;
  public logFail: boolean = environment.logFail;
  public logExecution: boolean = environment.logExecution;
  public logSuccess: boolean = environment.logSuccess;

  constructor() { }

  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   // return an ErrorObservable with a user-facing error message
  //   return new ErrorObservable(null);
  //   // return new ErrorObservable('Something bad happened; please try again later.');
  // };

}