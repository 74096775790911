import { Component, OnDestroy, OnInit, } from '@angular/core';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { Observable } from 'rxjs';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { UserService } from 'app/services/user/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/operators';
import { OrderService } from 'app/services/order/order.service';
// import { smoothlyMenu } from 'app/app.helpers';

declare var $: any;

@Component({
  selector: 'starter',
  templateUrl: 'starter.template.html'
})
export class StarterViewComponent implements OnDestroy, OnInit {
  private className: string = this.constructor.name;

  public nav: any;
  public tokenResp$: Observable<Object>;
  public userResume$: Observable<Object>;
  public openOrders: Object | null = null;
  public updatingData: boolean = false;

  isInStandaloneMode: boolean = false;
  isAndroid: boolean = false;
  isIos: boolean = false;

  public constructor(
    private auth: AuthService,
    protected afAuth: AngularFireAuth,
    public userService: UserService,
    public orderService: OrderService,
    private debug: DebugService,
  ) {
    this.debug.show(this.className, 'Iniciado...');
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";
    this.getOpenOrders();
  }

  public ngOnDestroy(): any {
    this.nav.classList.remove("white-bg");
    this.stopUpdatesOrderData();
  }

  // toggleNavigation(): void {
  //   $("body").toggleClass("mini-navbar");
  //   smoothlyMenu();
  // }

  // checkCellPhone(){
  //   const userAgent = window.navigator.userAgent.toLowerCase();

  //   // Detects if device is on iOS 
  //   const isAndroid = (/android/.test(userAgent));

  //   // Detects if device is on iOS 
  //   const isIos = (/iphone|ipad|ipod/.test(userAgent));
    
  //   this.isInStandaloneMode = (('standalone' in (window as any).navigator) && ((window as any).navigator.standalone) || (window.matchMedia('(display-mode: standalone)').matches));

  //   if(isAndroid || isIos){
  //     this.toggleNavigation();
  //   }
    
  // }

  /**
   * getOpenOrders
   */
  public getOpenOrders() {
    this.debug.show(this.className + '::getOpenOrders()');

    this.afAuth.authState.pipe(
      take(1)
    ).subscribe(user => {
      this.debug.show(this.className + '::getOpenOrders::authState>listening', user);

      if (user && !this.auth.waitNewUserData) {

        this.updateOrderData(user.uid);

        this.orderUpdadeInterval = setInterval(() => {
          this.updatingData = true;
          this.updateOrderData(user.uid);
        }, 15000);

      }
    });

  }

  public orderUpdadeInterval = null;

  /**
   * startUpdateOrderData
   */
  public updateOrderData(uid: string) {
    this.orderService.getAllOpenOrders(uid).pipe(
      take(1)
    ).subscribe(result => {
      this.updatingData = false;
      this.openOrders = result;
    });
  }

  /**
   * grandTotal
   */
  public grandTotal(currOrder) {
    return parseFloat(currOrder['pdc_total_itens']) + parseFloat(currOrder['pdc_total_taxa_servico']) + parseFloat(currOrder['pdc_total_desconto']) + parseFloat(currOrder['pdc_total_ajuste']);
  }

  /**
   * stopUpdates
   */
  public stopUpdatesOrderData() {
    clearInterval(this.orderUpdadeInterval);
  }

  public orderDetails: object | null = null;
  public showViewOrderDetails: boolean = false;
  public loadingDetails: number | null = null;

  /**
   * loadOrderDetails
   */
  public loadOrderDetails(pdc_id) {
    this.debug.show(this.className + '::loadOrderDetails()', pdc_id);

    if (this.orderDetails && this.orderDetails['pdc_id'] && pdc_id == this.orderDetails['pdc_id'] && this.showViewOrderDetails) {
      this.orderDetails = null;
      this.showViewOrderDetails = false;
    } else {
      this.loadingDetails = pdc_id;
      this.orderDetails = null;

      this.afAuth.authState.pipe(
        take(1)
      ).subscribe(user => {
        this.debug.show(this.className + '::loadOrderDetails::authState>listening', user);

        if (user && !this.auth.waitNewUserData) {

          this.orderService.getUserOrderDetais(user.uid, pdc_id).pipe(
            take(1)
          ).subscribe(result => {
            this.orderDetails = result;
            this.orderDetails['showHistoric'] = false;
            this.showViewOrderDetails = true;
            this.loadingDetails = null;
          });

        }
      });
    }
  }

  /**
   * toogleOrderHistoric
   */
  public toogleOrderHistoric() {
    if(this.orderDetails['showHistoric'] == true){
      this.orderDetails['showHistoric'] = false;
    } else {
      this.orderDetails['showHistoric'] = true;
    }
  }

  /**
   * showOrderDetails
   */
  public showOrderDetails(pdc_id) {
    return (this.orderDetails && this.orderDetails['pdc_id'] && pdc_id == this.orderDetails['pdc_id'])
  }

}
