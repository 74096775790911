import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Address } from 'app/services/interface/address';
import { UserService } from 'app/services/user/user.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddressService } from 'app/services/address/address.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {
  private className: string = this.constructor.name;

  public nav: any;
  statesList: Object | null = null;
  citiesList: Object | null = null;
  districtsList: Object | null = null;

  formAddress: FormGroup;
  addressEditing: object = null;
  public showAddressForm = false;

  defautState: string =  'SC';
  defautCity: number =  4699;
  defautDistrict: number =  1;

  constructor(
    public userService: UserService,
    public addressService: AddressService,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
    this.nav = document.querySelector('nav.navbar');

    this.formAddress = this.formBuilder.group({
      inputLogradouro: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      inputNumero: [null, [Validators.pattern(/^[\d\wçáéíóúàèìòùâêîôûãõñ\.\ -]*$/), Validators.maxLength(8)]],
      inputComplemento: [null, [Validators.pattern(/^[\d\wçáéíóúàèìòùâêîôûãõñ\.\ -]*$/), Validators.maxLength(80)]],
      inputCEP: [null, [Validators.pattern(/^(\d{2}\.\d{3}\-\d{3}|\d{5}\-\d{3}|\d{8})$/)]],
      inputBairro: [this.defautDistrict, [Validators.required]],
      inputCidade: [this.defautCity, [Validators.required]],
      inputEstado: [this.defautState, [Validators.required]],
      inputTipo: [false]
    });

    this.getStates(this.defautState);

    // for (const motive of this.lang.motives.fields.checkboxes) {
    //   this.motiveArrChecks.push(this.formBuilder.group({
    //     isSelected: this.OpcaoDoHospede('motivo.checkboxes', motive.id, false),
    //     text: motive.text,
    //     id: motive.id
    //   }));
    // }
    // for (const motive of this.lang.motives.fields.inputs) {
    //   this.motiveArrInputs.push(this.formBuilder.group({
    //     placeHolder: motive.placeHolder,
    //     value: this.OpcaoDoHospede('motivo.inputs', motive.id, motive.defaultValue),
    //     id: motive.id,
    //     colWidth: motive.colWidth
    //   }));
    // }

  }

  reachDistricts$: Subscription = null;
  reachCities$: Subscription = null;

  public ngOnInit(): any {
    this.nav.className += " white-bg";
  }

  getStates(selectState: string = null) {
    this.debug.show(this.className + '::getStates::param(selectState)', selectState);

    this.addressService.getStates().pipe(
      take(1)
    ).subscribe(
      states => {
        this.statesList = states;
        if (selectState !== null) {
          this.formAddress.get('inputEstado').setValue(selectState);
          this.getCities(selectState, this.defautCity);
        }
      }
    );
  }

  getCities(state: string = null, selectCity: number = null) {
    this.debug.show(this.className + '::getCities::param(state, selectCity)', state + ", " + selectCity);

    this.addressService.getCities(state).pipe(
      take(1)
    ).subscribe(
      cities => {
        this.citiesList = cities;
        if (selectCity !== null) {
          this.formAddress.get('inputCidade').setValue(selectCity);
          this.getDistricts(selectCity, this.defautDistrict);
        }
      }
    );
  }

  getDistricts(city: number = null, selectDistrict: number = null) {
    this.debug.show(this.className + '::getDistricts::param(city, selectDistrict)', city + ", " + selectDistrict);

    this.addressService.getDistricts(city).pipe(
      take(1)
    ).subscribe(
      districts => {
        this.districtsList = districts;
        if (selectDistrict !== null) {
          this.formAddress.get('inputBairro').setValue(selectDistrict);
        } else {
          this.formAddress.get('inputBairro').setValue(1);
        }
      }
    );
  }

  onSubmit() {
    // console.log(this.formAddress);
  }

  addressForm(status: string, data?: object) {
    if (status == 'show') {
      this.showAddressForm = true;
      if (data) {
        this.setAddressFormData(data);
      } else {
        this.formAddress.reset();
        this.addressEditing = null;
        this.getStates(this.defautState);
      }
    } else if (status == 'hide') {
      this.showAddressForm = false;
      this.formAddress.reset();
      this.addressEditing = null;
    } else if (status == 'toggle') {
      this.showAddressForm = !this.showAddressForm;
    }
  }

  setAddressFormData(data: object) {
    this.debug.show(this.className + '::setAddressFormData::param(data)', data);

    this.formAddress.patchValue({
      'inputLogradouro': data['end_logradouro'],
      'inputNumero': data['end_numero'],
      'inputComplemento': data['end_complemento'],
      'inputCEP': data['end_cep'],
      'inputBairro': data['bro_id'],
      'inputCidade': data['cdd_id'],
      'inputEstado': data['uf_sigla'],
      'inputTipo': (data['etp_id'] * 1 === 1),
    });

    // this.formAddress.get('inputTipo').setValue(1);

    this.addressEditing = data;
  }

  mainAddress(id: number) {
    this.userService.mainAddress(id);
  }

  deleteAddress(id: number) {
    this.userService.deleteAddress(id);
  }

  saveNewAdrress() {

    this.debug.show(this.className + "::saveNewAdrress()", 'Salvando endereço');

    let eData: Address = {
      end_id: ((this.addressEditing) ? this.addressEditing['end_id'] : null),
      end_logradouro: this.formAddress.get('inputLogradouro').value,
      end_numero: this.formAddress.get('inputNumero').value,
      end_complemento: this.formAddress.get('inputComplemento').value,
      end_obs: "",
      end_cep: this.formAddress.get('inputCEP').value.replace(/.-/g, ''),
      bro_id: this.formAddress.get('inputBairro').value,
      cdd_id: this.formAddress.get('inputCidade').value,
      etp_id: ((this.formAddress.get('inputTipo').value) ? 1 : 2)
    };
    
    if (!this.addressEditing) {
      this.debug.show(this.className + "::saveNewAdrress()", 'Novo endereço...');
      this.userService.newAddress(eData);
    } else {
      this.debug.show(this.className + "::saveNewAdrress()", 'Editando endereço...');
      this.userService.updateAddress(eData);
    }
    this.debug.show(this.className + "::saveNewAdrress()", eData);

    this.addressForm('hide');

  }

  setMainAddress(end_id: number) {

    this.debug.show(this.className + "::setMainAddress()", 'Selecinando como endereço principal');
    
    this.userService.mainAddress(end_id);

    this.addressForm('hide');

  }

}
