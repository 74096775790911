import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { DataService } from 'app/services/shared/data/data.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  private className: string = this.constructor.name;
  
  private sub = new SubSink();
  private selectedCategorySlug = null;

  constructor(
    public dataService: DataService,
    public shoppingService: ShoppingService,
    private route: ActivatedRoute,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {

    this.sub.sink = this.route.params.subscribe(params => {
      if ('categorySlug' in params) {
        this.selectedCategorySlug = params['categorySlug'];
      } else {
        this.selectedCategorySlug = null;
      }
    });

    this.shoppingService.getCategories();

  }

  activeCategory(category: object) {
    if (this.selectedCategorySlug === category['itg_palavra_chave'] || (this.selectedCategorySlug === null && category['itg_principal'] === '1')) {
      if (this.shoppingService.selectedCategory === null || this.shoppingService.selectedCategory['itg_palavra_chave'] !== this.selectedCategorySlug) {
        this.selectedCategorySlug = category['itg_palavra_chave'];
        this.shoppingService.selectCategory(category);
      }
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.debug.show(this.className, 'ngOnDestroy');
    this.sub.unsubscribe();
  }

}
