import { Component, OnInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { formatDate } from '@angular/common';
import { DataService } from 'app/services/shared/data/data.service';

@Component({
  selector: 'help-information',
  templateUrl: './help-information.component.html',
  styleUrls: ['./help-information.component.css']
})
export class HelpInformationComponent implements OnInit {
  private className: string = this.constructor.name;

  constructor(
    public dataService: DataService,
    public shoppingService: ShoppingService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  formatHour(hour) {
    return new Date('1970-01-01T' + hour + '-0300');
  }

  formatShortHour(hour) {
    return hour.replace(/(\:\d\d)$/, "");
  }

  sacContact(contacts) {
    for (let contact of contacts) {
      if ('ctp_id' in contact && contact['ctp_id'] * 1 == 4) {
        return this.autoFormatPhone(contact['ctt_telefone_a']);
      }
    }
  }

  autoFormatPhone(value) {
    value = value.replace(/[^0-9]{0,11}/g, '').toString();
    value = ((value.length > 10) ? value.substr(0, 10) : value);
    var len = value.length;
    if(len == 10) {
      return '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '.' + value.substr(6, 4);
    } else {
      return value.substr(0, 4) + '.' + value.substr(4, 4);
    }
  }

}
