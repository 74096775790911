import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { DataService } from 'app/services/shared/data/data.service';
import { SubSink } from 'subsink';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit, OnDestroy, AfterViewInit {
  private className: string = this.constructor.name;

  private sub = new SubSink();

  constructor(
    public dataService: DataService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private debug: DebugService
  ) {

    this.debug.show(this.className, 'Iniciado...');

    this.sub.sink = this.router.events.subscribe((e: any) => {
      // Se o evento NavigationEnd for interceptado retorna para a lista de produtos
      if (e instanceof NavigationEnd) {
        this.shoppingService.backProductList();
      }
    });
  }

  ngOnInit() {
    this.sub.sink = this.activeRoute.parent.params.subscribe(params => {
      this.shoppingService.selectStore(params['storeNickname']);
    });

    this.sub.sink = this.modalShoppingService.callActionModal.subscribe(param => {
      this.debug.show(this.className + '::listen->callActionModal::param', param);
      this.modalShoppingService.setContent(param.content);
      if (param.toOpen) {
        this.openDynamicModal();
      } else {
        this.fecharModal();
      }
    });

    this.activeRoute.parent.params
      .pipe(
        take(1)
      )
      .subscribe(params => {
        if (params['storeNickname'] == '@bellapizzavda') {
          this.shoppingService.loadFbPixel('./assets/js/fbpbellapizzavda.js');
        }
      });

  }

  ngAfterViewInit() {

  }

  fecharModal() {
    $('#dynamicModal').modal('hide');
  }

  openDynamicModal() {

    let _shoppingService = this.shoppingService;

    $('#dynamicModal').on('hidden.bs.modal', function (e) {
      if (_shoppingService.currOrder['delivery_type'] == 'E' && _shoppingService.currOrder['delivery_address'] == null) {

        _shoppingService.setNullDeliveryType();
        if (_shoppingService.currParameters['aceitarEntrega'] && !_shoppingService.currParameters['aceitarRetirada']) {
          // alert('Você não selecionou um endereço para entrega, sendo assim selecionaremos o seu endereço padrão.');
          // _shoppingService.setDeliveryTypeToDefaultUserAddress();
        } else if (!_shoppingService.currParameters['aceitarEntrega'] && _shoppingService.currParameters['aceitarRetirada']) {
          _shoppingService.setDeliveryTypeToTakeOut();
        } else if (_shoppingService.currParameters['aceitarEntrega'] && _shoppingService.currParameters['aceitarRetirada']) {
          alert('Você não selecionou um endereço para entrega, sendo assim vamos marcar a opção para você retirar no balcão.');
          _shoppingService.setDeliveryTypeToTakeOut();
        } else {
          alert('Você não selecionou um endereço para entrega, sendo assim vamos marcar a opção para você retirar no balcão.');
          _shoppingService.setDeliveryTypeToTakeOut();
        }

      }
    });

    if (this.modalShoppingService.requestedContent !== 'login') {
      if (!this.auth.isLoggedIn) {
        this.modalShoppingService.currContent = 'login';
      }
    }

    $('#dynamicModal').modal('show');

    if (this.modalShoppingService.currContent === 'login' || this.modalShoppingService.currContent === 'newUser') {
      $('#dynamicModal > .modal-dialog').addClass('modal-xl modal-login bglogin').removeClass('modal-default bgdefault');
    } else {
      $('#dynamicModal > .modal-dialog').addClass('modal-default bgdefault').removeClass('modal-xl modal-login bglogin');
    }

  }

  private closedStoreModalIsOpen: boolean = false;
  showClosedStoreModal() {
    if (!this.closedStoreModalIsOpen) {
      this.closedStoreModalIsOpen = true;
      $('#closedStoreModal').modal('show');
    }
  }

  ngOnDestroy() {
    this.shoppingService.resetOrder();
    this.debug.show(this.className, 'ngOnDestroy');
    this.sub.unsubscribe();

    $('head > script').remove();
  }

}
