import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// Módulos
import { ShoppingModule } from './shopping/shopping.module';
import { OrdersModule } from './orders/orders.module';
import { StoresModule } from './stores/stores.module';
import { UsersModule } from './users/users.module';

// Serviços
import { DataService } from 'app/services/shared/data/data.service';
import { AddressService } from 'app/services/address/address.service';
import { UserService } from 'app/services/user/user.service';
import { StoreService } from 'app/services/store/store.service';
import { OrderService } from 'app/services/order/order.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';


@NgModule({
  imports: [
    BrowserModule,
    ShoppingModule,
    OrdersModule,
    StoresModule,
    UsersModule
  ],
  declarations: [
  ],
  providers: [
    DataService,
    AddressService,
    UserService,
    StoreService,
    OrderService,
    ModalShoppingService,
    ShoppingService,
  ]
})
export class ModulesModule { }
