import { Component, OnInit } from '@angular/core';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';

declare var $: any;

@Component({
  selector: 'modal-content-new-user',
  templateUrl: './modal-content-new-user.component.html',
  styleUrls: ['./modal-content-new-user.component.css']
})
export class ModalContentNewUserComponent implements OnInit {

  constructor(
    public data: DataService,
    public modalShoppingService: ModalShoppingService,
  ) { }

  ngOnInit() {
  }

  addressNewUser() {

    const inputNewUserName: any = document.getElementById('nusr_email');
    const inputNewUserPass: any = document.getElementById('nusr_pass');
    const inputNewUserConfirmPass: any = document.getElementById('nusr_confirm_pass');

    this.modalShoppingService.isLoading = true;
    if (this.data.isValidEmail(inputNewUserName.value)) {
      if (inputNewUserPass.value === inputNewUserConfirmPass.value) {
        if (this.data.isStrongerPassword(inputNewUserPass.value)) {
          this.modalShoppingService.newUserEmail = inputNewUserName.value;
          this.modalShoppingService.newUserPass = inputNewUserPass.value;
          this.modalShoppingService.currContent = 'newUserData';
          $('#dynamicModal > .modal-dialog').addClass('modal-default bgdefault').removeClass('modal-xl modal-login bglogin');

        } else {
          alert('Sua senha é muito fácil');
        }
      } else {
        alert('As senhas não são iguais');
      }
    } else {
      alert('Email inválido');
    }
    this.modalShoppingService.isLoading = false;
  }

  openLoginForm() {
    this.modalShoppingService.currContent = 'login';
  }

  fecharModal() {
    $('#dynamicModal').modal('hide');
  }

}
