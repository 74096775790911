import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    // console.warn(navigator);
    if ('serviceWorker' in navigator && environment.production) {
      // alert("há service worker");
      navigator.serviceWorker.register('ngsw-worker.js', {
        scope: '/'
      }).then(registration => {
        // alert("Service Worker registration completed ...")
      });
    } else {
      // alert("não há service worker");
    }
  }).catch(err => console.log(err));
