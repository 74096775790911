import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';

declare var $: any;

@Component({
  selector: 'modal-content-login',
  templateUrl: './modal-content-login.component.html',
  styleUrls: ['./modal-content-login.component.css']
})
export class ModalContentLoginComponent implements OnInit, AfterViewInit {

  inputUserName: any = null;
  inputUserPass: any = null;

  constructor(
    public data: DataService,
    private auth: AuthService,
    private userService: UserService,
    public modalShoppingService: ModalShoppingService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  // isValidUserName(e = null) {
  //   alert('chamou');
  //   let currElement: any = null;
  //   if (isNull(e)) {
  //     currElement = document.getElementById('usr_email');
  //   } else {
  //     currElement = e.target;
  //   }

  //   if (currElement.value.length > 0) {
  //     window.alert(this.data.isValidEmail(currElement.value));
  //   }

  //   // console.warn(teste.value);
  //   // e.target.value.length);
  //   // if(e.target.value.length > 0){

  //   // }
  // }

  public emailLogin() {
    this.modalShoppingService.isLoading = true;
    const user = this.prepareEmailLogin();
    // console.warn(user);
    this.auth.SignIn(user.usr_email, user.usr_pass)
      .then((result) => {
        if (typeof (result) !== 'undefined' && result !== null && result) {
          this.userService.getLoggedUserData();
          if (this.modalShoppingService.currContent === this.modalShoppingService.requestedContent) {
            $('#dynamicModal').modal('hide');
          } else {
            this.modalShoppingService.currContent = this.modalShoppingService.requestedContent;
            $('#dynamicModal > .modal-dialog').addClass('modal-default bgdefault').removeClass('modal-xl modal-login bglogin');
          }
        }
      })
      .finally(() => {
        this.modalShoppingService.isLoading = false;
      });
  }

  prepareEmailLogin() {
    this.inputUserName = document.getElementById('usr_email');
    this.inputUserPass = document.getElementById('usr_pass');
    const loginData = {
      "usr_email": this.inputUserName.value,
      "usr_pass": this.inputUserPass.value,
    };
    return loginData;
  }

  openNewUserForm() {
    this.modalShoppingService.currContent = 'newUser';
  }

  fecharModal() {
    $('#dynamicModal').modal('hide');
  }

}
