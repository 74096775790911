import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// HUNGRY Services
import { DebugService } from './shared/debug/debug.service';
import { DataService } from './shared/data/data.service';
import { NotifyService } from './shared/notify/notify.service';
import { HandleErrorService } from './shared/error/handle-error.service';
import { PermissionService } from './shared/permission/permission.service';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    DebugService,
    DataService,
    AuthService,
    AuthGuard,
    NotifyService,
    HandleErrorService,
    PermissionService,
  ]
})
export class SharedServicesModule { }
