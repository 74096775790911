import { Component, OnInit, NgZone } from '@angular/core';
import { FirebaseUser } from 'app/services/shared/auth/firebase-user';
import { DataService } from 'app/services/shared/data/data.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { of } from 'rxjs';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'modal-new-user-data-form',
  templateUrl: './modal-new-user-data-form.component.html',
  styleUrls: ['./modal-new-user-data-form.component.css']
})
export class ModalNewUserDataFormComponent implements OnInit {
  private className: string = this.constructor.name;

  fullnameError: boolean = false;
  cellphoneError: boolean = false;
  birthDateError: boolean = false;

  fullname: string = "";
  cellphone: string = "";
  birthDate: string = "";
  gender: string = "";
  genderName: string = "";

  constructor(
    public auth: AuthService,
    protected afAuth: AngularFireAuth,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public userService: UserService,
    public dataService: DataService,
    public shoppingService: ShoppingService,
    public modalShoppingService: ModalShoppingService,
    private debug: DebugService,
  ) { }

  ngOnInit() {
  }

  checkRequiredStepFields() {
    if (this.checkFullName() && this.checkCellphone() && this.checkBirthDate()) {
      const inputFullname: any = document.getElementById('usr_fullname');
      const inputCellPhone: any = document.getElementById('usr_cellphone');
      const inputBirthDate: any = document.getElementById('usr_birth_date');
      const inputGender: any = document.getElementById('usr_gender');

      if (inputGender.selectedIndex == -1)
        return false;

      this.fullname = inputFullname.value;
      this.cellphone = inputCellPhone.value;
      this.birthDate = inputBirthDate.value;
      this.gender = inputGender.value;
      this.genderName = inputGender.options[inputGender.selectedIndex].text;

      return true;
    }
    return false;
  }

  checkFullName() {
    const inputFullname: any = document.getElementById('usr_fullname');
    this.fullnameError = ((this.separateFullName(inputFullname.value)) ? false : true);
    return !this.fullnameError;
  }

  separateFullName(fullname) {
    if (fullname) {
      fullname = fullname.toString().trim().replace(/(\s)+/g, " ");
      let regxFirst = /(^(([\w\u0080-\u00FF]*)\s)*)/ig;
      let regxLast = /(\s([\w\u0080-\u00FF]*)$)/ig;
      let firstName = fullname.match(regxFirst) || [];
      let lastName = fullname.match(regxLast) || [];
      if (firstName[0] && lastName[0]) {
        return { first_name: (firstName[0]).toString().trim(), last_name: (lastName[0]).toString().trim() };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  checkCellphone() {
    const inputCellPhone: any = document.getElementById('usr_cellphone');
    this.cellphoneError = ((inputCellPhone.value.length == 16) ? false : true);
    return !this.cellphoneError;
  }

  cellphoneMask() {
    const inputCellPhone: any = document.getElementById('usr_cellphone');
    const newValue = this.dataService.formattingFone(inputCellPhone.value);
    if (inputCellPhone.value !== newValue) {
      inputCellPhone.value = newValue;
    }
  }

  checkBirthDate() {
    const inputBirthDate: any = document.getElementById('usr_birth_date');
    this.birthDateError = ((inputBirthDate.value.length === 10 && this.dataService.isValidDate(inputBirthDate.value)) ? false : true);
    return !this.birthDateError;
  }

  birthDateMask() {
    const inputBirthDate: any = document.getElementById('usr_birth_date');
    const newValue = this.dataService.formattingData(inputBirthDate.value);
    if (inputBirthDate.value !== newValue) {
      inputBirthDate.value = newValue;
    }
  }

  previousCartStep() {
    this.shoppingService.previousUserStep();
  }

  next() {
    if (this.checkRequiredStepFields()) {
      this.saveNewUser();
    }
  }

  getDBFormatDate() {
    return this.birthDate.toString().split("/").reverse().join("-");
  }

  saveNewUser() {
    this.debug.show(this.className + "::saveNewUser()", 'Novo usuário...');

    this.modalShoppingService.isLoading = true;

    const userName = this.separateFullName(this.fullname);
    const ljId = ((this.shoppingService.currOrder['order_destination']) ? this.shoppingService.currOrder['order_destination'] : null);

    if (userName !== null) {

      let userData = {
        usr_uid: null,
        lj_id: ljId,
        usr_telefone: this.cellphone.replace(/(\(|\)|\s|\.|\-)/g, ''),
        usr_email: this.modalShoppingService.newUserEmail,
        pf_nome: userName['first_name'],
        pf_sobrenome: userName['last_name'],
        pf_cpf: null,
        pf_rg: null,
        pf_data_nascimento: this.getDBFormatDate(),
        pf_genero: this.gender,
        contatos: [
          {
            ctt_nome: userName['first_name'] + ' ' + userName['last_name'],
            ctt_telefone_a: this.cellphone.replace(/(\(|\)|\s|\.|\-)/g, ''),
            ctt_telefone_b: null,
            ctt_email: this.modalShoppingService.newUserEmail,
            ctt_skype: null,
            ctp_id: 1
          }
        ]
      };

      this.auth.SignUp(this.modalShoppingService.newUserEmail, this.modalShoppingService.newUserPass)
        .then((result) => {
          if (typeof (result) !== 'undefined' && result !== null && result !== false) {

            this.debug.show(this.className + '::SignUp', 'Usuário criado no firebase!');

            userData.usr_uid = result['uid'];

            this.userService.newUser(userData).pipe(
              take(1)
            ).subscribe(resp => {

              this.debug.show(this.className + '::newUser', 'Usuário criado no DB!');

              this.auth.setActiveToken(null);
              this.auth.waitNewUserData = false;
              this.auth.SetUserData(result);

              this.ngZone.run(() => {
                this.auth.getNewToken()
                  .pipe(
                    take(1)
                  ).subscribe(resp => {
                    if ('token' in resp) {
                      this.auth.setActiveToken(resp['token']);

                      // this.userService.getLoggedUserData();

                      this.afAuth.authState.subscribe(user => {
                        this.debug.show(this.className + '::authState>listening', user);

                        if (user && !this.auth.waitNewUserData) {
                          this.userService.getUserByUid(user.uid).pipe(
                            take(1)
                          ).subscribe(data => {
                            this.userService.userData = data;
                            this.userService.userSessionData$ = of<FirebaseUser>({
                              uid: user.uid,
                              email: user.email,
                              displayName: user.displayName,
                              photoURL: user.photoURL,
                              emailVerified: user.emailVerified
                            });

                            this.modalShoppingService.isLoading = false;
                            // if (this.shoppingService.currOrder['delivery_type'] == 'E') {
                            //   this.shoppingService.setDeliveryTypeToDefaultUserAddress();
                            // }
                            if (this.shoppingService.currCartStep == 2) {
                              this.shoppingService.currUserStep = 1;
                            } else {
                              this.shoppingService.closeLoginModal();
                              // this.shoppingService.closeCartModal();
                            }
                          });

                        } else {
                          this.userService.userData = null;
                          this.userService.userSessionData$ = null;
                          this.modalShoppingService.isLoading = false;
                        }
                      });

                    } else {
                      this.auth.SignOut();
                      alert('Há algum problema com a geração de token.');
                      this.modalShoppingService.isLoading = false;
                    }
                  });
              });
            });
          } else {
            this.modalShoppingService.isLoading = false;
          }
        });

    } else {
      this.modalShoppingService.isLoading = false;
      this.fullnameError = true;
    }

  }

}
