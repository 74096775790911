import { Component, OnInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';

declare var $: any;

@Component({
  selector: 'modal-cart-content-payment-type',
  templateUrl: './modal-cart-content-payment-type.component.html',
  styleUrls: ['./modal-cart-content-payment-type.component.css']
})
export class ModalCartContentPaymentTypeComponent implements OnInit {

  private className: string = this.constructor.name;

  constructor(
    public userService: UserService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  async checkIfPaymentTypeIsSelected() {
    if (this.shoppingService.currOrder['payment_type']) {
      this.shoppingService.nextCartStep();
    } else {
      $('.payment-type-button').removeClass('animated bounceIn');
      $('.payment-type-error').removeClass('d-none');
      await this.shoppingService.delay(150);
      $('.payment-type-button').addClass('animated bounceIn');
    }
  }

}
