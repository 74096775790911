import { Component, OnInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';

declare var $: any;

@Component({
  selector: 'product-options',
  templateUrl: './product-options.component.html',
  styleUrls: ['./product-options.component.css']
})
export class ProductOptionsComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    animated: true,
    keyboard: true,
    class: 'modal-xl'
  };

  constructor(
    public shoppingService: ShoppingService,
    private debug: DebugService
  ) {
  }

  ngOnInit() {
  }

  addToOrder() {
    this.shoppingService.addToOrder(this.shoppingService.selectedProduct);
    this.shoppingService.backProductList();
  }

  addToOrderAndShowCart() {
    this.addToOrder();
    this.shoppingService.scrollToObj('cart_box');
  }

  removerSubitem(subItemIndex) {
    this.shoppingService.selectedProduct['selectedSubItems'].splice(subItemIndex, 1);
  }

  removerComplement(complementIndex) {
    this.shoppingService.selectedProduct['selectedComplements'].splice(complementIndex, 1);
  }

  openSubitemsList() {
    if (this.shoppingService.acceptsMoreSubitems()) {
      $('#subitemModal').modal('show', this.config);
    }
  }

  openComplementsList() {
    if (this.shoppingService.acceptsMoreComplements()) {
      $('#complementModal').modal('show', this.config);
    }
  }

}