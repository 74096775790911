import { BrowserModule } from '@angular/platform-browser';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'pt');

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

// Bootstraps
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// App views
import { DashboardsModule } from './views/dashboards/dashboards.module';
import { AppviewsModule } from './views/appviews/appviews.module';

// App modules/components
import { LayoutsModule } from './components/common/layouts/layouts.module';

// HUNGRY
import { ConfigComponent } from './services/shared/config/config.component';

import { FirebaseConfig } from './services/shared/config/firebase.config';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { SharedServicesModule } from './services/shared-services.module';
import { JwtInterceptor } from './services/shared/http/interceptor/jwt-interceptor';
import { ModulesModule } from './views/modules/modules.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { interval } from 'rxjs';


@NgModule({
  declarations: [
    AppComponent,
    ConfigComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      { preventDuplicates: true }
    ),
    DashboardsModule,
    SharedServicesModule,
    ModulesModule,
    LayoutsModule,
    AppviewsModule,
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: 'reload' }),
    AngularFireModule.initializeApp(FirebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor() { }
}
