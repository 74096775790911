import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DebugService } from '../debug/debug.service';
import { ToastrService } from 'ngx-toastr';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { take } from 'rxjs/operators';

@Injectable()
export class DataService {
	private className: string = this.constructor.name;
	public thisYear: number = new Date().getFullYear();
	public systemDetails: object = {
		copyright: '© 2019-' + this.thisYear + ' - Hungry Delivery Club'
	}

	currStore: object = { 'storeNickname': null, 'data': null };
	currSelCity: Object | null = null;

	constructor(
		private http: HttpClient,
		private debug: DebugService,
		private toastr: ToastrService
	) {
		this.debug.show(this.className, 'Iniciado...');
	}

	isStrongerPassword(value) {
		if (value.length >= 8) {
			return true;
		}
		return false;
	}

	isValidEmail(value) {
		if (value.length >= 10) {
			return true;
		}
		return false;
	}

	isValidDate(value) {
		return /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))(\/|-|\.)|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)))(?:(?:1[6-9]|[2-9]\d)?\d{2})( (0?\d|1\d|2[0-3])\:(0?\d|[1-5]\d)\:(0?\d|[1-5]\d))?$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))(\/|-|\.)(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))( (0?\d|1\d|2[0-3])\:(0?\d|[1-5]\d)\:(0?\d|[1-5]\d))?$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:(?:1[6-9]|[2-9]\d)?\d{2})( (0?\d|1\d|2[0-3])\:(0?\d|[1-5]\d)\:(0?\d|[1-5]\d))?$/g.test(value);
	}

	autoFormatPhone(value) {
		value = value.replace(/[^0-9]{0,11}/g, '').toString();
		var newValue = '';
		value = ((value.length > 11) ? value.substr(0, 11) : value);
		var len = value.length;
		newValue += ((len > 0) ? '(' + value.substr(0, 2) : '');
		newValue += ((len > 2 && len < 11) ? ') ' + value.substr(2, 4) : ((len === 11) ? ') ' + value.substr(2, 2) + ' ' + value.substr(4, 3) : ''));
		newValue += ((len > 6 && len < 11) ? '.' + value.substr(6, 4) : ((len === 11) ? '.' + value.substr(7, 4) : ''));
		return newValue;
	}

	formattingFone(value) {
		value = value.replace(/[^0-9]{0,11}/g, '').toString();
		var newValue = '';
		value = ((value.length > 11) ? value.substr(0, 11) : value);
		var len = value.length;
		newValue += ((len > 0) ? '(' + value.substr(0, 2) : '');
		newValue += ((len > 2 && len <= 4) ? ') ' + value.substr(2, 2) : ((len > 4) ? ') ' + value.substr(2, 2) + ' ' + value.substr(4, 3) : ''));
		newValue += ((len > 7) ? '.' + value.substr(7, 4) : '');
		return newValue;
	}

	autoFormatCNPJ(value) {
		value = value.replace(/[^0-9]{0,14}/g, '').toString();
		var newValue = '';
		value = ((value.length > 14) ? value.substr(0, 14) : value);
		var len = value.length;
		newValue += ((len > 0) ? value.substr(0, 2) : '');
		newValue += ((len > 2) ? '.' + value.substr(2, 3) : '');
		newValue += ((len > 5) ? '.' + value.substr(5, 3) : '');
		newValue += ((len > 8) ? '/' + value.substr(8, 4) : '');
		newValue += ((len > 12) ? '-' + value.substr(12, 2) : '');
		return newValue;
	}

	autoFormatCPF(value) {
		value = value.replace(/[^0-9]{0,11}/g, '').toString();
		var newValue = '';
		value = ((value.length > 11) ? value.substr(0, 11) : value);
		var len = value.length;
		newValue += ((len > 0) ? value.substr(0, 3) : '');
		newValue += ((len > 3) ? '.' + value.substr(3, 3) : '');
		newValue += ((len > 6) ? '.' + value.substr(6, 3) : '');
		newValue += ((len > 9) ? '-' + value.substr(9, 2) : '');
		return newValue;
	}

	formattingCEP(value) {
		value = value.replace(/[^0-9]{0,8}/g, '').toString();
		var newValue = '';
		value = ((value.length > 8) ? value.substr(0, 8) : value);
		var len = value.length;
		newValue += ((len > 0) ? value.substr(0, 2) : '');
		newValue += ((len > 2) ? '.' + value.substr(2, 3) : '');
		newValue += ((len > 5) ? '-' + value.substr(5, 3) : '');
		return newValue;
	}

	formattingData(value) {
		value = value.replace(/[^0-9]{0,8}/g, '').toString();
		var newValue = '';
		value = ((value.length > 8) ? value.substr(0, 8) : value);
		var len = value.length;
		newValue += ((len > 0) ? value.substr(0, 2) : '');
		newValue += ((len > 2) ? '/' + value.substr(2, 2) : '');
		newValue += ((len > 4) ? '/' + value.substr(4, 4) : '');
		return newValue;
	}

	/**
	 * extract
	 * @description Recebe a resposta do web service(JSON) e extrai somente os dados relevantes.
	 * 
	 * @param data JSON retornado pelo web service.
	 * @returns Object (JSON) Apenas dados relevantes para uso.
	 * 
	 */
	public extract(data: Object): Object {
		this.debug.show(this.className + '::extract::param(data)', data, true);

		if (!('error' in data)) {
			this.debug.show(this.className + '::extract', 'Erro na estrutura de resposta do webservice...', false);
			throw ({ "error": true, "message": "Erro na estrutura de resposta do webservice..." });
		}

		if (data['error']) {
			this.debug.show(this.className + '::extract', 'WEB SERVICE SAY: errorCode(' + data['status'] + ') - ' + data['message'], false);

			this.toastr.info(this.userMesage(data['status']), "Hungry Delivery", {
				enableHtml: true,
				closeButton: true,
				tapToDismiss: false,
				timeOut: 30000,
				extendedTimeOut: 30000,
				positionClass: 'toast-bottom-center',
				// toastClass: 'ngx-toastr bg-red-to-orange-230 toaster-install'
			})
				.onTap
				.pipe(take(1))
				.subscribe(() => {
					if (data['status'] == 118)
						window.location.reload();
				});

			throw ({ "error": true, "message": "Erro no webservice..." });
		}

		if (!('result' in data)) {
			this.debug.show(this.className + '::extract', 'Erro na estrutura de resposta do webservice...', false);
			throw ({ "error": true, "message": "Erro na estrutura de resposta do webservice..." });
		}

		return data['result'];
	}

	/**
	 * userMessage
	 */
	public userMesage(status: number): string {
		let message = "";
		switch (status) {
			case 118:
				message = '<div>Precisamos atualizar antes de continuar.</div><div class="p-t-xs cursor">Atualizar agora!</div>';
				break;

			default:
				message = "Infelizmente sua solicitação não pode ser atendida no momento pelo servidor. Tente novamente em alguns instantes.";
				break;
		}

		return message;
	}

}
