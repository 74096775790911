import { Component, OnInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DataService } from 'app/services/shared/data/data.service';

declare var $: any;

@Component({
  selector: 'modal-content-closed-store',
  templateUrl: './modal-content-closed-store.component.html',
  styleUrls: ['./modal-content-closed-store.component.css']
})
export class ModalContentClosedStoreComponent implements OnInit {

  constructor(
    public dataService: DataService,
    public shoppingService: ShoppingService,
  ) { }

  ngOnInit() {
  }

  formatShortHour(hour) {
    return hour.replace(/(\:\d\d)$/, "");
  }

  fecharModal() {
    this.shoppingService.browsingMenu = true;
    $('#closedStoreModal').modal('hide');
  }

}
