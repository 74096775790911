import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { correctHeight, smoothlyMenu } from 'app/app.helpers';
import 'jquery-slimscroll';

declare var $: any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html',
  styles: ['.nav > li > a i {min-width: 15px; text-align: center;}']
})

export class NavigationComponent {

  isInStandaloneMode: boolean = false;
  isAndroid: boolean = false;
  isIos: boolean = false;

  constructor(private router: Router) { }

  ngAfterViewInit() {
    let sideMenu = $('#side-menu').metisMenu();
    sideMenu.on('shown.metisMenu', function (e) {
      correctHeight();
    });

    $("body").addClass("fixed-sidebar");

    if ($("body").hasClass('fixed-sidebar')) {
      $('.sidebar-collapse').slimscroll({
        height: '100%'
      }).css("overflow", "hidden").css("width", "auto").css("height", "100%");
    }
  }

  goToRouter(route: any){
    this.checkCellPhone();
    this.router.navigate(route);
  }

  toggleNavigation(): void {
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

  checkCellPhone(){
    const userAgent = window.navigator.userAgent.toLowerCase();

    // Detects if device is on iOS 
    const isAndroid = (/android/.test(userAgent));

    // Detects if device is on iOS 
    const isIos = (/iphone|ipad|ipod/.test(userAgent));
    
    this.isInStandaloneMode = (('standalone' in (window as any).navigator) && ((window as any).navigator.standalone) || (window.matchMedia('(display-mode: standalone)').matches));

    if(isAndroid || isIos){
      this.toggleNavigation();
    }
    
  }

}
