import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';

@Injectable()
export class PermissionService {


  private profileId: any = null;
  // private profileName:any = null;
  private listOfPermissions: string[] = [];


  public constructor() {}

  public getCurrentProfile() {
    return this.profileId;
  }
  /**
   * @description Indica ao serviço qual perfil vinculado ao usuário logado deve ser lido e avaliado no 
   * banco de dados do firebase para validar as permissões de acesso em tempo de execução.
   * @returns void
   */
  public setCurrentProfile(profile) {
    if (this.getCurrentProfile() !== profile['pfl_id']) {
      this.profileId = profile['pfl_id'];
      void this.loadListOfPermissions();
    }
  }

  private loadListOfPermissions() {
    this.listOfPermissions.slice(0);
    firebase.database().ref('profilePermissions/' + this.profileId)
      .once('value', snapshot => { this.updateListOfPermissions(snapshot.val()) });
  }

  private updateListOfPermissions(snapshot) {
    this.listOfPermissions = snapshot;
    return snapshot;
  }

  /**
   * @description Indica se a permissão requerida está contida na lista de permissões do perfil do 
   * usuário logado.
   * @returns boolean
   */
  public havePermission(permissionCod) {
    return (this.listOfPermissions.indexOf(permissionCod) > -1);
  }

}
