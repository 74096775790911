import { Component, OnInit } from '@angular/core';

import { UserService } from 'app/services/user/user.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { StoreService } from 'app/services/store/store.service';

@Component({
  selector: 'modal-content-list-addresses',
  templateUrl: './modal-content-list-addresses.component.html',
  styleUrls: ['./modal-content-list-addresses.component.css']
})
export class ModalContentListAddressesComponent implements OnInit {
  private className: string = this.constructor.name;

  constructor(
    public userService: UserService,
    public storeService: StoreService,
    public shoppingService: ShoppingService,
    public modalShoppingService: ModalShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) { }

  ngOnInit() {
  }

  deleteAddress(id: number) {
    this.userService.deleteAddress(id);
  }

  selectAddress(address: object) {
    this.shoppingService.selectAddress(address);
  }

  isSelectedAddress(address: object) {
    if (this.shoppingService.currOrder['delivery_address']) {
      if (this.shoppingService.currOrder['delivery_address']['end_id'] === address['end_id']) {
        return true;
      }
    }
  }

  setMainAddress(end_id: number) {
    this.debug.show(this.className + "::setMainAddress()", 'Selecinando como endereço principal');
    this.userService.mainAddress(end_id);
  }

  editAddress(address) {
    this.modalShoppingService.showActionModal('addressData', address);
  }

  
  newAddress(){
    
  }

}
