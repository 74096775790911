import { Component, OnInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';

declare var $: any;

@Component({
  selector: 'modal-user-form',
  templateUrl: './modal-user-form.component.html',
  styleUrls: ['./modal-user-form.component.css']
})
export class ModalUserFormComponent implements OnInit {
  private className: string = this.constructor.name;

  inputUserName: any = null;
  inputUserPass: any = null;

  constructor(
    public userService: UserService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  public emailLogin() {
    this.modalShoppingService.isLoading = true;
    const user = this.prepareEmailLogin();
    this.auth.SignIn(user.usr_email, user.usr_pass)
      .then((result) => {
        if (typeof (result) !== 'undefined' && result !== null && result) {
          this.userService.getLoggedUserData();
          this.shoppingService.closeLoginModal();
        }
      })
      .finally(() => {
        this.modalShoppingService.isLoading = false;
      });
  }

  prepareEmailLogin() {
    this.inputUserName = document.getElementById('usr_email');
    this.inputUserPass = document.getElementById('usr_pass');
    const loginData = {
      "usr_email": this.inputUserName.value,
      "usr_pass": this.inputUserPass.value,
    };
    return loginData;
  }

  async showNewUserForm() {
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutLeft');
    await this.shoppingService.delay(150);
    this.shoppingService.showNewUserForm = true;
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInRight');
  }

  async showForgotPasswordForm(){
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeOutLeft');
    await this.shoppingService.delay(150);
    this.shoppingService.showForgotPasswordForm = true;
    $('#cartModal').removeClass('animated fadeInLeft fadeOutLeft fadeInRight fadeOutRight').addClass('animated fadeInRight');
  }

}