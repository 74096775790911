import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  private className: string = this.constructor.name;

  constructor(protected config: ConfigService) {
    this.show(this.className, 'Iniciado...');
  }

  /**
   * show
   */
  public show(debugLocal: string, debugMessage?: any, debugType?: boolean | null) {
    if (this.config.showDebug) {
      if (debugType === true) {
        console.log('%cSUCCESS >> ' + debugLocal.toUpperCase(), "background:#f2ffda; color:#378a00;padding:6px 10px");
        if(!(debugMessage === "") && !(debugMessage === null) && !(debugMessage === undefined) ) {
          console.log(debugMessage);
        }
      } else if (debugType === false) {
        console.log('%cERROR >> ' + debugLocal.toUpperCase(), "background:#fff0f0; color:#ff0000;padding:6px 10px;");
        if(!(debugMessage === "") && !(debugMessage === null) && !(debugMessage === undefined) ) {
          console.log(debugMessage);
        }
      } else {
        console.log('%c>> ' + debugLocal.toUpperCase(), "background:#f0f0f0; color:#222;padding:6px 10px;");
        if(!(debugMessage === "") && !(debugMessage === null) && !(debugMessage === undefined) ) {
          console.log(debugMessage);
        }
      }
    }

    if (this.config.logGenerate) {
      this.logGenerate(debugLocal, debugMessage, debugType);
    }
  }

  /**
   * logGenerate
   */
  private logGenerate(debugLocal: string, debugMessage: any, debugType?: boolean | null) {
    console.log('gerando log...');
  }


}
