import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { retry, take, catchError, map, switchMap } from 'rxjs/operators';

import { FirebaseUser } from 'app/services/shared/auth/firebase-user';
import { Address } from 'app/services/interface/address';

import { DataService } from '../shared/data/data.service';
import { HttpClient } from '@angular/common/http';
import { HandleErrorService } from '../shared/error/handle-error.service';
import { DebugService } from '../shared/debug/debug.service';
import { AuthService } from '../shared/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { of, EMPTY } from 'rxjs';
import { environment } from 'environments/environment';
import { SubSink } from 'subsink';

@Injectable()
export class UserService implements OnDestroy {
  private className: string = this.constructor.name;

  public userData: Object | null = null;
  public userSessionData$: Observable<FirebaseUser | null> = null;
  public orders: Object | null = null;
  public userDataNewAddressForm: boolean = false;
  private sub = new SubSink();

  constructor(
    private authService: AuthService,
    protected afAuth: AngularFireAuth,
    protected http: HttpClient,
    protected httpError: HandleErrorService,
    protected dataService: DataService,
    protected debug: DebugService) {

    this.debug.show(this.className, 'Iniciado...');

    this.getLoggedUserData();
  }

  /**
   * getFullName
   */
  public getFullName(UserData) {
    if ('pes_tipo' in UserData) {
      if (UserData['pes_tipo'] == 'PF') {
        return UserData['pf_nome'] + ' ' + UserData['pf_sobrenome'];
      }
      if (UserData['pes_tipo'] == 'PJ') {
        return UserData['pj_nome'];
      }
    }
    return "";
  }

  /**
   * getName
   */
  public getName(UserData) {
    if ('pes_tipo' in UserData) {
      if (UserData['pes_tipo'] == 'PF') {
        return UserData['pf_nome'];
      }
      if (UserData['pes_tipo'] == 'PJ') {
        return UserData['pj_nome'];
      }
    }
    return "";

  }

  /**
   * getName
   */
  public getNameDotsTruncate(UserData, maxSize = 30) {
    let dots = '';

    if ('pes_tipo' in UserData) {
      if (UserData['pes_tipo'] == 'PF') {
        dots = (UserData['pf_nome']).length > maxSize ? '...' : '';
        return (UserData['pf_nome']).substring(0, maxSize) + dots;
      }
      if (UserData['pes_tipo'] == 'PJ') {
        dots = (UserData['pj_nome']).length > maxSize ? '...' : '';
        return (UserData['pj_nome']).substring(0, maxSize);
      }
    }
    return "";

  }

  public getUserDeliveryAddress(uid: string, storeNickname: (string | null) = null): Observable<Object> {
    if (uid && storeNickname) {
      return this.http.get(environment.hungryApiUrl + "/user/deliveryaddresses/" + uid + "?store=" + storeNickname)
        .pipe(
          map(resp => (this.dataService.extract(resp))),
          take(1),
          retry(2),
          catchError(this.httpError.handleHttpError)
        );
    } else {
      return null;
    }
  }

  // public authUserState(): Observable<Object> {
  //   return this.afAuth.authState.pipe(
  //     map(user => {
  //       console.warn('USUARIO ****************************');
  //       console.warn(user);
  //       if (user && !this.authService.waitNewUserData) {
  //         this.userSessionData$ = of<FirebaseUser>({
  //           uid: user['uid'],
  //           email: user['email'],
  //           displayName: user['displayName'],
  //           photoURL: user['photoURL'],
  //           emailVerified: user['emailVerified']
  //         });
  //         return this.teste(user);
  //       } else {
  //         this.userSessionData$ = null;
  //         return null;
  //       }
  //     })
  //   );
  // }

  // public teste(user: Object): Object | null {

  //   console.warn('EXECUTANDO TESTE AGORA: ');

  //   return this.getUserByUid(user['uid']).pipe(
  //     map(data => {
  //       console.warn('DADOS DO USUARIO AGORA: ');
  //       console.warn(data);
  //       // if (ljNickname) {
  //       //   return this.getUserDeliveryAddress(user['uid'], ljNickname)
  //       //     .pipe(
  //       //       map(extraData => {
  //       //         // console.warn('OLD DATA: ');
  //       //         // console.warn(data);
  //       //         data['enderecos_entrega'] = extraData;
  //       //         // console.warn('NEW DATA: ');
  //       //         // console.warn(data);
  //       //       })
  //       //     );
  //       // }
  //       return data;
  //     })
  //   );
  // }

  public getLoggedUserData() {
    this.debug.show(this.className + '::getLoggedUserData');

    // this.authUserState()
    //   .subscribe(data => {
    //     // console.error('RESPOSTA FINAL ***************************************');
    //     // console.error(data);
    //     this.userData = data
    //   });


    this.sub.sink = this.afAuth.authState.subscribe(user => {
      this.debug.show(this.className + '::getLoggedUserData::authState>listening', 'CARREGANDO DADOS DO USUÁRIO');
      this.debug.show(this.className + '::getLoggedUserData::authState>listening', user);

      if (user && !this.authService.waitNewUserData) {
        this.getUserByUid(user['uid']).pipe(
          take(1)
        ).subscribe(data => {
          this.userData = data
          if (this.dataService.currStore['storeNickname']) {
            this.debug.show(this.className + '::getLoggedUserData::authState::getUserByUid', 'LOJA CARREGADA');
            this.sub.sink = this.getUserDeliveryAddress(user['uid'], this.dataService.currStore['storeNickname'])
              .subscribe(extraData => {
                if (extraData['data']) {
                  this.userData['enderecos_entrega'] = extraData['data'];
                } else {
                  this.userData['enderecos_entrega'] = null;
                }
              });
          } else {
            this.debug.show(this.className + '::getLoggedUserData::authState::getUserByUid', 'FALTA A LOJA');
            this.userData['enderecos_entrega'] = null;
          }
        });

        this.userSessionData$ = of<FirebaseUser>({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified
        });
      } else {
        this.userData = null;
        this.userSessionData$ = null;
      }
    });
  }

  public getUserByUid(reqUid: string): Observable<Object> {
    this.debug.show(this.className + '::getUserByUid::param(reqUid)', reqUid);

    return this.http.get(environment.hungryApiUrl + "user/" + reqUid)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        retry(2),
        catchError(this.httpError.handleHttpError)
      );

  }

  public newUser(data) {
    this.debug.show(this.className + '::newUser::param(data)', data);

    return this.http.post(environment.hungryApiUrl + "user", data)
      .pipe(
        map(resp => (this.dataService.extract(resp))),
        take(1),
        // retry(2),
        catchError(this.httpError.handleHttpError)
      );
  }

  public newAddress(data: Address) {
    this.debug.show(this.className + '::newAddrress::param(data)', data);

    this.sub.sink = this.userSessionData$.subscribe(sessionData => {
      if (sessionData && sessionData.uid) {
        this.http.post(environment.hungryApiUrl + "user/" + sessionData.uid + "/address", [data])
          .pipe(
            map(resp => (this.dataService.extract(resp))),
            take(1),
            // retry(2),
            catchError(this.httpError.handleHttpError)
          ).subscribe(resp => this.getLoggedUserData());
      } else {
        this.debug.show(this.className + '::newAddrress', 'Dados da sessão não carregados!', false);
      }
    });
  }

  public deleteAddress(data: number) {
    this.debug.show(this.className + '::deleteAddress::param(data)', data);

    this.sub.sink = this.userSessionData$.subscribe(sessionData => {
      if (sessionData && sessionData.uid) {
        this.http.delete(environment.hungryApiUrl + "user/" + sessionData.uid + "/address/" + data)
          .pipe(
            map(resp => (this.dataService.extract(resp))),
            take(1),
            retry(2),
            catchError(this.httpError.handleHttpError)
          ).subscribe(resp => this.getLoggedUserData());
      } else {
        this.debug.show(this.className + '::deleteAddress', 'Dados da sessão não carregados!', false);
      }
    });
  }

  public updateAddress(data: Address) {
    this.debug.show(this.className + '::updateAddress::param(data)', data);

    this.sub.sink = this.userSessionData$.subscribe(sessionData => {
      if (sessionData && sessionData.uid) {
        this.http.put(environment.hungryApiUrl + "user/" + sessionData.uid, { 'updateAddress': true, 'enderecos': [data] })
          .pipe(
            map(resp => (this.dataService.extract(resp))),
            take(1),
            retry(2),
            catchError(this.httpError.handleHttpError)
          ).subscribe(resp => this.getLoggedUserData());
      } else {
        this.debug.show(this.className + '::updateAddress', 'Dados da sessão não carregados!', false);
      }
    });
  }

  public mainAddress(data: number) {
    this.debug.show(this.className + '::mainAddress::param(data)', data);

    this.sub.sink = this.userSessionData$.subscribe(sessionData => {
      if (sessionData && sessionData.uid) {
        this.http.put(environment.hungryApiUrl + "user/" + sessionData.uid + "/mainaddress", { 'end_id': data })
          .pipe(
            map(resp => (this.dataService.extract(resp))),
            take(1),
            retry(2),
            catchError(this.httpError.handleHttpError)
          ).subscribe(resp => this.getLoggedUserData());
      } else {
        this.debug.show(this.className + '::mainAddress', 'Dados da sessão não carregados!', false);
      }
    });
  }

  ngOnDestroy() {
    this.debug.show(this.className, 'ngOnDestroy');
    this.sub.unsubscribe();
  }

}