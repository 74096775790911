import { Component, OnInit, OnDestroy } from '@angular/core';
import { detectBody } from '../../../../app.helpers';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'app/services/shared/data/data.service';

declare var jQuery: any;

@Component({
  selector: 'basic-shopping',
  templateUrl: './basic-shopping-layout.component.html',
  styleUrls: ['./basic-shopping-layout.component.css'],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class BasicShoppingLayoutComponent implements OnInit, OnDestroy {
  // storeNickname: string;
  sub: any;

  constructor(
    public dataService: DataService,
    private route: ActivatedRoute
  ) { }

  ngAfterViewInit() {
    jQuery("body").addClass("fixed-nav").addClass("canvas-menu");
  }

  ngOnInit(): any {
    this.sub = this.route.params.subscribe(params => {
      this.dataService.currStore['storeNickname'] = params['storeNickname'];
    });
    detectBody();
  }

  onResize() {
    detectBody();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}