import { Component, OnInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { DataService } from 'app/services/shared/data/data.service';

@Component({
  selector: 'advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.css']
})
export class AdvertisingComponent implements OnInit {
  private className: string = this.constructor.name;

  constructor(
    public dataService: DataService,
    public shoppingService: ShoppingService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  // insertHTML(node, htmlString){

  //   console.warn(node);
  //   // return document.createRange().createContextualFragment(htmlString);
  //   let DOMFrag = document.createRange().createContextualFragment(htmlString);
  //   console.warn(DOMFrag);

  //   return true;
  // }

  ngOnInit() {
  }

}
