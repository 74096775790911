import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { Subscription } from 'rxjs';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';

declare var $: any;

@Component({
  selector: 'modal-cart-content-delivery-type',
  templateUrl: './modal-cart-content-delivery-type.component.html',
  styleUrls: ['./modal-cart-content-delivery-type.component.css']
})
export class ModalCartContentDeliveryTypeComponent implements OnInit, AfterViewInit {
  private className: string = this.constructor.name;

  subDeliveryType: Subscription = null;
  addressActionItem: number = -1;

  constructor(
    public dataService: DataService,
    public userService: UserService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
    this.subDeliveryType = this.shoppingService.toChangeDeliveryType.subscribe(param => {
      this.selectDeliveryType({ "target": { "value": param.newType } });
    });

    this.userService.getLoggedUserData();

    this.shoppingService.setNullDeliveryType();
  }

  ngAfterViewInit(){
    // if (this.shoppingService.currParameters['aceitarEntrega'] && this.userService.userData) {
      // this.shoppingService.getUserDeliveryAddress();
    // }
  }

  openNewAddressForm(){
    this.userService.userDataNewAddressForm = true;
  }

  selectDeliveryTypeToTakeOut() {
    this.shoppingService.setDeliveryTypeToTakeOut();
  }

  deleteAddress(id: number) {
    this.userService.deleteAddress(id);
  }

  selectAddress(address: object) {
    this.shoppingService.selectAddress(address);
  }

  isSelectedAddress(address: object) {
    if (this.shoppingService.currOrder['delivery_address']) {
      if (this.shoppingService.currOrder['delivery_address']['end_id'] === address['end_id']) {
        return true;
      }
    }
    return false;
  }

  setMainAddress(end_id: number) {
    this.debug.show(this.className + "::setMainAddress()", 'Selecinando como endereço principal');
    this.userService.mainAddress(end_id);
  }

  editAddress(address) {
    this.modalShoppingService.showActionModal('addressData', address);
  }

  openActionModal(content) {
    this.modalShoppingService.showActionModal(content);
  }

  openAddressActionBar(index) {
    if (this.addressActionItem == index) {
      this.addressActionItem = -1;
    } else {
      this.addressActionItem = index;
    }
  }

  selectDeliveryType(e) {

    this.debug.show(this.className + '::selectDeliveryType', e);
    let deliveryType = null;

    if (this.shoppingService.currParameters['aceitarEntrega'] && !this.shoppingService.currParameters['aceitarRetirada']) {
      deliveryType = false;
      e.target.checked = true;
    } else if (!this.shoppingService.currParameters['aceitarEntrega'] && this.shoppingService.currParameters['aceitarRetirada']) {
      deliveryType = true;
      e.target.checked = true;
    } else if (this.shoppingService.currParameters['aceitarEntrega'] && this.shoppingService.currParameters['aceitarRetirada']) {
      if (this.shoppingService.currOrder['delivery_type'] !== null) {
        deliveryType = !(this.shoppingService.currOrder['delivery_type'] == 'B')
      } else {
        deliveryType = (e.target.value == "B")
      }
    } else {
      deliveryType = true;
      alert('Houve um problema ao carregar as formas de entrega aceitas pelo estabelecimento. Tente mais tarde!');
    }

    if (!deliveryType) {
      this.shoppingService.setDeliveryTypeToDefaultUserAddress();
    } else {
      this.shoppingService.setDeliveryTypeToTakeOut();
    }
  }

}
