import { Component } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.css']
})
export class OpenOrdersComponent {

  constructor() {}

}
