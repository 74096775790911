import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import * as firebase from 'firebase/app';

import { FirebaseUser } from "./firebase-user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { AuthService } from './auth.service';
import { NotifyService } from '../notify/notify.service';

import { Observable } from 'rxjs/Observable';
import { map, take, tap } from 'rxjs/operators';
import { DebugService } from '../debug/debug.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private className: string = this.constructor.name;

  constructor(
    private auth: AuthService,
    private router: Router,
    private debug: DebugService) {
    this.debug.show(this.className, 'Iniciado...');
  }

  canActivate(
    currRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isLoggedIn = this.auth.isLoggedIn;

    this.debug.show(this.className + '::canActivate', 'Usuário esta logado? ' + ((!isLoggedIn) ? 'Não' : 'Sim'));

    if (!isLoggedIn && (!('allowPublicMode' in currRoute.data) || currRoute.data['allowPublicMode'] === false)) {
      this.router.navigate(['/login']);
    }

    if ('allowPublicMode' in currRoute.data && currRoute.data['allowPublicMode'] === true) {
      this.auth.allowPublicMode = true;
      this.auth.activePublicToken();
    } else {
      this.auth.allowPublicMode = false;
    }

    return true;
  }

  private havePermission(permissionCod: string) {
    let permission = false;
    if (permissionCod !== undefined) {
      permission = this.auth.havePermission(permissionCod);
      if (!permission) {
        this.router.navigate(['/access-denied']);
      }
    } else {
      permission = true;
    }

    this.debug.show(this.className + '::havePermission', ((permissionCod !== undefined) ? 'Tem permissão (' + permissionCod + ')? ' + ((permission) ? 'Sim' : 'Não') : 'Tem permissão pois não exige uma permissão específica!'));

    return permission;
  }
}