import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit, OnDestroy {

  constructor(
    public shoppingService: ShoppingService,
    private route: ActivatedRoute,
    private debug: DebugService
  ) { }

  ngOnInit() {
  }

  /**
   * selectOrAddProduct
   * @param product 
   */
  selectOrAddProduct(product: object) {
    if ('itm_item_montagem' in product && product['itm_item_montagem'] * 1 != 0 && ((product['totalCountSubItems'] * 1) > 0 || (product['totalCountComplements'] * 1) > 0 || (product['totalCountIngredients'] * 1) > 0 || (product['totalCountExtras'] * 1) > 0)) {
      this.shoppingService.selectProduct(product)
      this.scrollTo('anchorTopStore');
    } else {
      this.shoppingService.addToOrder(product);
    }
  }

  scrollTo(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth" });
  }

  ngOnDestroy() {

  }

}
