import { Component, OnInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { DataService } from 'app/services/shared/data/data.service';

@Component({
  selector: 'timing-information',
  templateUrl: './timing-information.component.html',
  styleUrls: ['./timing-information.component.css']
})
export class TimingInformationComponent implements OnInit {
  private className: string = this.constructor.name;

  constructor(
    public dataService: DataService,
    public shoppingService: ShoppingService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  formatShortHour(hour) {
    return hour.replace(/(\:\d\d)$/, "");
  }

}
