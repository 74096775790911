import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { Subscription } from 'rxjs';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';
import { StoreService } from 'app/services/store/store.service';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'modal-cart-content-new-address',
  templateUrl: './modal-cart-content-new-address.component.html',
  styleUrls: ['./modal-cart-content-new-address.component.css']
})
export class ModalCartContentNewAddressComponent implements OnInit, AfterViewInit {
  private className: string = this.constructor.name;

  isSaving: boolean = false;

  addressCEP: string = "";
  addressState: string = "";
  addressCity: string = "";
  addressCityName: string = "";
  addressDistrict: string = "";
  addressDistrictName: string = "";
  addressStreet: string = "";
  addressNumber: string = "";
  addressComplement: string = "";
  addressReferencePoint: string = "";
  addressMain: number = 0;

  stateError: boolean = false;
  cityError: boolean = false;
  streetError: boolean = false;
  districtError: boolean = false;
  CEPError: boolean = false;
  numberError: boolean = false;
  complementError: boolean = false;
  referencePointError: boolean = false;

  stateSelected: boolean = false;
  citySelected: boolean = false;
  districtSelected: boolean = false;

  reachStates: Object | null = null;
  reachCities: Object | null = null;
  reachDistricts: Object | null = null;

  constructor(
    public dataService: DataService,
    public userService: UserService,
    private storeService: StoreService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // if (this.modalShoppingService.contentData) {
    //   this.setAddressData();
    // }
    this.getReachStates();
    this.isSaving = false;
  }

  getReachStates() {
    this.storeService.getReachStates(this.dataService.currStore['storeNickname'])
      .pipe(
        take(1)
      )
      .subscribe(
        states => {
          this.reachStates = states;
          this.reachCities = null;
          this.reachDistricts = null;
          this.stateSelected = false;
          this.citySelected = false;
          if (this.storeService.defaultState !== null) {
            this.getReachCities(this.storeService.defaultState);
          }
        }
      );
  }

  getReachCities(uf_sigla: string) {
    this.storeService.getReachCities(this.dataService.currStore['storeNickname'], uf_sigla)
      .pipe(
        take(1)
      )
      .subscribe(
        cities => {
          this.reachCities = cities;
          this.reachDistricts = null;
          this.citySelected = false;
          if (this.storeService.defaultCity !== null) {
            this.getReachDistricts(this.storeService.defaultCity);
          }
        }
      );
  }

  getReachDistricts(cdd_id: number) {
    this.storeService.getReachDistricts(this.dataService.currStore['storeNickname'], cdd_id)
      .pipe(
        take(1)
      )
      .subscribe(
        districts => {
          this.districtSelected = false;
          if (districts != 'No data found or disabled.') {
            this.reachDistricts = districts;
          } else {
            this.reachDistricts = null;
            alert('Entre em contato conosco e informe que seu bairro não está disponível no sistema.');
          }
        });
  }

  loadCities() {
    const inputState: any = document.getElementById('usr_state');
    this.stateSelected = true;
    this.citySelected = false;
    this.districtSelected = false;
    this.reachDistricts = null;
    this.getReachCities(inputState.value);
  }

  loadDistricts() {
    const inputCity: any = document.getElementById('usr_city');
    this.citySelected = true;
    this.districtSelected = false;
    this.reachDistricts = null;
    this.getReachDistricts(inputCity.value);
  }

  selectDefaultState(statetData: object) {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['uf_sigla']) {
      if (this.modalShoppingService.contentData['uf_sigla'] == statetData['uf_sigla']) {
        this.stateSelected = true;
        return true;
      } else {
        return false;
      }
    } else {
      if (this.storeService.defaultState !== null && this.storeService.defaultState == statetData['uf_sigla']) {
        this.stateSelected = true;
        return true;
      } else {
        return false;
      }
    }
  }

  selectDefaultCity(cityData: object) {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['cdd_id']) {
      if (this.modalShoppingService.contentData['cdd_id'] == cityData['cdd_id']) {
        this.citySelected = true;
        return true;
      } else {
        return false;
      }
    } else {
      if (this.storeService.defaultCity !== null && this.storeService.defaultCity == cityData['cdd_id']) {
        this.citySelected = true;
        return true;
      } else {
        return false;
      }
    }
  }

  selectDefaultDistrict(districtData: object) {
    if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['bro_id']) {
      if (this.modalShoppingService.contentData['bro_id'] == districtData['bro_id']) {
        this.districtSelected = true;
        return true;
      } else {
        return false;
      }
    } else {
      if (this.storeService.defaultDistrict !== null && this.storeService.defaultDistrict == districtData['bro_id']) {
        this.districtSelected = true;
        return true;
      } else {
        return false;
      }
    }
  }

  checkCEP() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    this.CEPError = ((inputAddressCEP.value.length === 10) ? false : true);
    return !this.CEPError;
  }

  CEPMask() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    const newValue = this.dataService.formattingCEP(inputAddressCEP.value);
    if (inputAddressCEP.value !== newValue) {
      inputAddressCEP.value = newValue;
    }
  }

  checkAddressStreet() {
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    this.streetError = (inputAddressStreet.value.length < 5 || inputAddressStreet.value.length > 255);
    return !this.streetError;
  }

  limitAddressStreet() {
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    if (inputAddressStreet.value.length > 255) {
      inputAddressStreet.value = inputAddressStreet.value.substring(0, 255);
    }
  }

  checkAddressNumber() {
    const inputAddressStreet: any = document.getElementById('usr_address_number');
    this.numberError = (inputAddressStreet.value.length > 8);
    return !this.numberError;
  }

  limitNumber() {
    const inputAddressNumber: any = document.getElementById('usr_address_number');
    if (inputAddressNumber.value.length > 8) {
      inputAddressNumber.value = inputAddressNumber.value.substring(0, 8);
    }
  }

  checkAddressComplement() {
    const inputAddressComplement: any = document.getElementById('usr_address_complement');
    this.complementError = (inputAddressComplement.value.length > 80);
    return !this.complementError;
  }

  limitComplement() {
    const inputAddressComplement: any = document.getElementById('usr_address_complement');
    if (inputAddressComplement.value.length > 80) {
      inputAddressComplement.value = inputAddressComplement.value.substring(0, 80);
    }
  }

  checkAddressReferencePoint() {
    const inputAddressReferencePoint: any = document.getElementById('usr_address_obs');
    this.referencePointError = (inputAddressReferencePoint.value.length > 80);
    return !this.referencePointError;
  }

  limitReferencePoint() {
    const inputAddressReferencePoint: any = document.getElementById('usr_address_obs');
    if (inputAddressReferencePoint.value.length > 255) {
      inputAddressReferencePoint.value = inputAddressReferencePoint.value.substring(0, 255);
    }
  }

  closeNewAddressForm() {
    this.userService.userDataNewAddressForm = false;
    this.shoppingService.setCartStep(2);
  }

  checkRequiredStepFields() {
    if (this.checkCEP() && this.checkAddressStreet() && this.checkAddressNumber() && this.checkAddressComplement()) {
      const inputAddressCEP: any = document.getElementById('usr_address_cep');
      const inputState: any = document.getElementById('usr_state');
      const inputCity: any = document.getElementById('usr_city');
      const inputDistrict: any = document.getElementById('usr_district');
      const inputAddressStreet: any = document.getElementById('usr_address_street');
      const inputAddressNumber: any = document.getElementById('usr_address_number');
      const inputAddressComplement: any = document.getElementById('usr_address_complement');
      const inputAddressReferencePoint: any = document.getElementById('usr_address_obs');
      // const inputMainAddress: any = document.getElementById('etp_id');

      if (inputState.selectedIndex == -1 || inputCity.selectedIndex == -1 || inputDistrict.selectedIndex == -1)
        return false;

      this.addressCEP = inputAddressCEP.value;
      this.addressState = inputState.value;
      this.addressCity = inputCity.value;
      this.addressCityName = inputCity.options[inputCity.selectedIndex].text;
      this.addressDistrict = inputDistrict.value;
      this.addressDistrictName = inputDistrict.options[inputDistrict.selectedIndex].text;
      this.addressStreet = inputAddressStreet.value;
      this.addressNumber = inputAddressNumber.value;
      this.addressComplement = inputAddressComplement.value;
      this.addressReferencePoint = inputAddressReferencePoint.value;
      // this.addressMain = ((inputMainAddress.checked) ? 1 : 2);
      this.addressMain = 1;

      return true;
    }
    return false;
  }
  
  async saveAddressData() {
    this.debug.show(this.className + "::saveAddressData()", 'Gravando endereço...');

    if (this.checkRequiredStepFields()) {

      this.userService.userData['enderecos_entrega'] = null;
      this.isSaving = true;

      let endereco = {
        end_logradouro: this.addressStreet,
        end_numero: this.addressNumber,
        end_complemento: this.addressComplement,
        end_obs: this.addressReferencePoint,
        end_cep: this.addressCEP.replace(/(\s|\.|\-)/g, ''),
        bro_id: parseInt(this.addressDistrict),
        cdd_id: parseInt(this.addressCity),
        etp_id: ((this.addressMain !== 1)? 2 : 1)
      };

      if (this.modalShoppingService.contentData && this.modalShoppingService.contentData['end_id']) {
        endereco['end_id'] = parseInt(this.modalShoppingService.contentData['end_id']);
        this.userService.updateAddress(endereco);
      } else {
        this.userService.newAddress(endereco);
      }

      await this.shoppingService.delay(300);
      this.closeNewAddressForm();
      this.isSaving = false;

    } else {
      alert("Preencha todos os campos corretamente.");
      this.isSaving = false;
    }

  }

}
