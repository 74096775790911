import { Component, OnInit, NgZone } from '@angular/core';
import { FirebaseUser } from 'app/services/shared/auth/firebase-user';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DataService } from 'app/services/shared/data/data.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from 'app/services/user/user.service';
import { StoreService } from 'app/services/store/store.service';

import { Subscription, of, Observable } from 'rxjs';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'modal-content-new-user-data',
  templateUrl: './modal-content-new-user-data.component.html',
  styleUrls: ['./modal-content-new-user-data.component.css']
})
export class ModalContentNewUserDataComponent implements OnInit {
  private className: string = this.constructor.name;

  maxStep: number = 3;
  currStep: number = 1;
  fullnameError: boolean = false;
  cellphoneError: boolean = false;
  birthDateError: boolean = false;

  fullname: string = "";
  cellphone: string = "";
  birthDate: string = "";
  gender: string = "";
  genderName: string = "";

  addressCEP: string = "";
  addressState: string = "";
  addressCity: string = "";
  addressCityName: string = "";
  addressDistrict: string = "";
  addressDistrictName: string = "";
  addressStreet: string = "";
  addressNumber: string = "";
  addressComplement: string = "";

  constructor(
    protected afAuth: AngularFireAuth,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public dataService: DataService,
    private auth: AuthService,
    private shoppingService: ShoppingService,
    private userService: UserService,
    private storeService: StoreService,
    public modalShoppingService: ModalShoppingService,
    private debug: DebugService,
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  setStep(step: number) {
    this.currStep = step;
  }

  previous() {
    if (this.currStep > 1) {
      this.currStep--;
    } else {
      this.currStep = 1;
      this.modalShoppingService.newUserEmail = "";
      this.modalShoppingService.newUserPass = "";
      this.modalShoppingService.currContent = 'newUser';
    }
  }

  next() {
    if (this.currStep < this.maxStep) {
      if (this.checkRequiredStepFields()) {
        this.currStep++;
        if (this.currStep === 2) {
          this.step2Init();
        }
      }
    } else if (this.currStep === this.maxStep) {
      if (this.checkRequiredStepFields()) {
        this.saveNewUser();
      }
    } else {
      this.currStep = 1;
    }
  }

  checkRequiredStepFields() {
    if (this.currStep === 1) {
      if (this.checkFullName() && this.checkCellphone() && this.checkBirthDate()) {
        const inputFullname: any = document.getElementById('usr_fullname');
        const inputCellPhone: any = document.getElementById('usr_cellphone');
        const inputBirthDate: any = document.getElementById('usr_birth_date');
        const inputGender: any = document.getElementById('usr_gender');

        if (inputGender.selectedIndex == -1)
          return false;

        this.fullname = inputFullname.value;
        this.cellphone = inputCellPhone.value;
        this.birthDate = inputBirthDate.value;
        this.gender = inputGender.value;
        this.genderName = inputGender.options[inputGender.selectedIndex].text;

        return true;
      }
    } else if (this.currStep === 2) {
      if (this.checkCEP() && this.checkAddressStreet() && this.checkAddressNumber() && this.checkAddressComplement()) {
        const inputAddressCEP: any = document.getElementById('usr_address_cep');
        const inputState: any = document.getElementById('usr_state');
        const inputCity: any = document.getElementById('usr_city');
        const inputDistrict: any = document.getElementById('usr_district');
        const inputAddressStreet: any = document.getElementById('usr_address_street');
        const inputAddressNumber: any = document.getElementById('usr_address_number');
        const inputAddressComplement: any = document.getElementById('usr_address_complement');

        if (inputState.selectedIndex == -1 || inputCity.selectedIndex == -1 || inputDistrict.selectedIndex == -1)
          return false;

        this.addressCEP = inputAddressCEP.value;
        this.addressState = inputState.value;
        this.addressCity = inputCity.value;
        this.addressCityName = inputCity.options[inputCity.selectedIndex].text;
        this.addressDistrict = inputDistrict.value;
        this.addressDistrictName = inputDistrict.options[inputDistrict.selectedIndex].text;
        this.addressStreet = inputAddressStreet.value;
        this.addressNumber = inputAddressNumber.value;
        this.addressComplement = inputAddressComplement.value;

        return true;
      }
    } else if (this.currStep === 3) {

      return true;
    }
    return false;
  }

  checkFullName() {
    const inputFullname: any = document.getElementById('usr_fullname');
    this.fullnameError = ((this.separateFullName(inputFullname.value)) ? false : true);
    return !this.fullnameError;
  }

  separateFullName(fullname) {
    if (fullname) {
      fullname = fullname.toString().trim().replace(/(\s)+/g, " ");
      let regxFirst = /(^(([\w\u0080-\u00FF]*)\s)*)/ig;
      let regxLast = /(\s([\w\u0080-\u00FF]*)$)/ig;
      let firstName = fullname.match(regxFirst) || [];
      let lastName = fullname.match(regxLast) || [];
      if (firstName[0] && lastName[0]) {
        return { first_name: (firstName[0]).toString().trim(), last_name: (lastName[0]).toString().trim() };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  checkCellphone() {
    const inputCellPhone: any = document.getElementById('usr_cellphone');
    this.cellphoneError = ((inputCellPhone.value.length == 16) ? false : true);
    return !this.cellphoneError;
  }

  cellphoneMask() {
    const inputCellPhone: any = document.getElementById('usr_cellphone');
    const newValue = this.dataService.formattingFone(inputCellPhone.value);
    if (inputCellPhone.value !== newValue) {
      inputCellPhone.value = newValue;
    }
  }

  checkBirthDate() {
    const inputBirthDate: any = document.getElementById('usr_birth_date');
    this.birthDateError = ((inputBirthDate.value.length === 10 && this.dataService.isValidDate(inputBirthDate.value)) ? false : true);
    return !this.birthDateError;
  }

  birthDateMask() {
    const inputBirthDate: any = document.getElementById('usr_birth_date');
    const newValue = this.dataService.formattingData(inputBirthDate.value);
    if (inputBirthDate.value !== newValue) {
      inputBirthDate.value = newValue;
    }
  }

  // ------------------------------------------------
  // Step 2

  step2Init() {
    this.getReachStates();
  }

  stateError: boolean = false;
  cityError: boolean = false;
  streetError: boolean = false;
  districtError: boolean = false;
  CEPError: boolean = false;
  numberError: boolean = false;
  complementError: boolean = false;

  stateSelected: boolean = false;
  citySelected: boolean = false;
  districtSelected: boolean = false;

  reachStates: Object | null = null;
  reachCities: Object | null = null;
  reachDistricts: Object | null = null;

  subStates$: Subscription = null;
  subCities$: Subscription = null;
  subDistricts$: Subscription = null;

  checkCEP() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    this.CEPError = ((inputAddressCEP.value.length === 10) ? false : true);
    return !this.CEPError;
  }

  CEPMask() {
    const inputAddressCEP: any = document.getElementById('usr_address_cep');
    const newValue = this.dataService.formattingCEP(inputAddressCEP.value);
    if (inputAddressCEP.value !== newValue) {
      inputAddressCEP.value = newValue;
    }
  }

  checkAddressStreet() {
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    this.streetError = (inputAddressStreet.value.length < 5 || inputAddressStreet.value.length > 255);
    return !this.streetError;
  }

  limitAddressStreet() {
    const inputAddressStreet: any = document.getElementById('usr_address_street');
    if (inputAddressStreet.value.length > 255) {
      inputAddressStreet.value = inputAddressStreet.value.substring(0, 255);
    }
  }

  checkAddressNumber() {
    const inputAddressStreet: any = document.getElementById('usr_address_number');
    this.numberError = (inputAddressStreet.value.length > 8);
    return !this.numberError;
  }

  limitNumber() {
    const inputAddressNumber: any = document.getElementById('usr_address_number');
    if (inputAddressNumber.value.length > 8) {
      inputAddressNumber.value = inputAddressNumber.value.substring(0, 8);
    }
  }

  checkAddressComplement() {
    const inputAddressComplement: any = document.getElementById('usr_address_complement');
    this.complementError = (inputAddressComplement.value.length > 80);
    return !this.complementError;
  }

  limitComplement() {
    const inputAddressComplement: any = document.getElementById('usr_address_complement');
    if (inputAddressComplement.value.length > 80) {
      inputAddressComplement.value = inputAddressComplement.value.substring(0, 80);
    }
  }

  selectDefaultState(statetData: object) {
    if (this.storeService.defaultState !== null && this.storeService.defaultState == statetData['uf_sigla']) {
      this.stateSelected = true;
      return true;
    } else {
      return false;
    }
  }

  getReachStates() {
    this.subStates$ = this.storeService.getReachStates(this.dataService.currStore['storeNickname']).subscribe(
      states => {
        this.reachStates = states;
        this.reachCities = null;
        this.reachDistricts = null;
        this.stateSelected = false;
        this.citySelected = false;
        if (this.storeService.defaultState !== null) {
          const inputState: any = document.getElementById('usr_state');
          inputState.value = this.storeService.defaultState;
          this.stateSelected = true;
          this.getReachCities(this.storeService.defaultState);
        }
      }
    );
  }

  loadCities() {
    const inputState: any = document.getElementById('usr_state');
    this.stateSelected = true;
    this.citySelected = false;
    this.districtSelected = false;
    this.reachDistricts = null;
    this.getReachCities(inputState.value);
  }

  selectDefaultCity(cityData: object) {
    if (this.storeService.defaultCity !== null && this.storeService.defaultCity == cityData['cdd_id']) {
      this.citySelected = true;
      return true;
    } else {
      return false;
    }
  }

  getReachCities(uf_sigla: string) {
    this.subCities$ = this.storeService.getReachCities(this.dataService.currStore['storeNickname'], uf_sigla).subscribe(
      cities => {
        this.reachCities = cities;
        this.reachDistricts = null;
        this.citySelected = false;
        if (this.storeService.defaultCity !== null) {
          this.getReachDistricts(this.storeService.defaultCity);
        }
      }
    );
  }

  loadDistricts() {
    const inputCity: any = document.getElementById('usr_city');
    this.citySelected = true;
    this.districtSelected = false;
    this.reachDistricts = null;
    this.getReachDistricts(inputCity.value);
  }

  selectDefaultDistrict(districtData: object) {
    if (this.storeService.defaultDistrict !== null && this.storeService.defaultDistrict == districtData['bro_id']) {
      this.districtSelected = true;
      return true;
    } else {
      return false;
    }
  }

  getReachDistricts(cdd_id: number) {
    this.subDistricts$ = this.storeService.getReachDistricts(this.dataService.currStore['storeNickname'], cdd_id).subscribe(
      districts => {
        this.districtSelected = false;
        if (districts != 'No data found or disabled.') {
          this.reachDistricts = districts;
        } else {
          this.reachDistricts = null;
          alert('Entre em contato conosco e informe que seu bairro não está disponível no sistema.');
        }
      }
    );
  }

  // -------------------------------------
  // Gravando dados do usuário

  getDBFormatDate() {
    return this.birthDate.toString().split("/").reverse().join("-");
  }

  saveNewUser() {
    this.debug.show(this.className + "::saveNewUser()", 'Novo usuário...');

    this.modalShoppingService.isLoading = true;

    const userName = this.separateFullName(this.fullname);
    const ljId = ((this.shoppingService.currOrder['order_destination']) ? this.shoppingService.currOrder['order_destination'] : null);

    if (userName !== null) {

      let userData = {
        usr_uid: null,
        lj_id: ljId,
        usr_telefone: this.cellphone.replace(/(\(|\)|\s|\.|\-)/g, ''),
        usr_email: this.modalShoppingService.newUserEmail,
        pf_nome: userName['first_name'],
        pf_sobrenome: userName['last_name'],
        pf_cpf: null,
        pf_rg: null,
        pf_data_nascimento: this.getDBFormatDate(),
        pf_genero: this.gender,
        contatos: [
          {
            ctt_nome: this.fullname,
            ctt_telefone_a: this.cellphone.replace(/(\(|\)|\s|\.|\-)/g, ''),
            ctt_telefone_b: null,
            ctt_email: this.modalShoppingService.newUserEmail,
            ctt_skype: null,
            ctp_id: 1
          }
        ],
        enderecos: [
          {
            end_logradouro: this.addressStreet,
            end_numero: this.addressNumber,
            end_complemento: this.addressComplement,
            end_cep: this.addressCEP.replace(/(\s|\.|\-)/g, ''),
            bro_id: this.addressDistrict,
            cdd_id: this.addressCity,
            etp_id: 1
          }
        ]
      };

      this.auth.SignUp(this.modalShoppingService.newUserEmail, this.modalShoppingService.newUserPass)
        .then((result) => {
          if (typeof (result) !== 'undefined' && result !== null && result !== false) {

            this.debug.show(this.className + '::SignUp', 'Usuário criado no firebase!');

            userData.usr_uid = result['uid'];

            this.userService.newUser(userData).pipe(
              take(1)
            ).subscribe(resp => {

              this.debug.show(this.className + '::newUser', 'Usuário criado no DB!');

              this.auth.setActiveToken(null);
              this.auth.waitNewUserData = false;
              this.auth.SetUserData(result);

              this.ngZone.run(() => {
                this.auth.getNewToken()
                  .pipe(
                    take(1)
                  ).subscribe(resp => {
                    if ('token' in resp) {
                      this.auth.setActiveToken(resp['token']);

                      // this.userService.getLoggedUserData();

                      this.afAuth.authState.subscribe(user => {
                        this.debug.show(this.className + '::authState>listening', user);

                        if (user && !this.auth.waitNewUserData) {
                          this.userService.getUserByUid(user.uid).pipe(
                            take(1)
                          ).subscribe(data => {
                            this.userService.userData = data;
                            this.userService.userSessionData$ = of<FirebaseUser>({
                              uid: user.uid,
                              email: user.email,
                              displayName: user.displayName,
                              photoURL: user.photoURL,
                              emailVerified: user.emailVerified
                            });

                            this.modalShoppingService.isLoading = false;
                            if(this.shoppingService.currOrder['delivery_type'] == 'E'){
                              this.shoppingService.setDeliveryTypeToDefaultUserAddress();
                            }
                            this.modalShoppingService.hideActionModal();

                          });

                        } else {
                          this.userService.userData = null;
                          this.userService.userSessionData$ = null;
                          this.modalShoppingService.isLoading = false;
                        }
                      });

                    } else {
                      this.auth.SignOut();
                      alert('Há algum problema com a geração de token.');
                      this.modalShoppingService.isLoading = false;
                    }
                  });
              });
            });
          } else {
            this.modalShoppingService.isLoading = false;
          }
        });

    } else {
      this.modalShoppingService.isLoading = false;
      this.fullnameError = true;
    }

  }

}
