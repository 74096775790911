import { Component, OnInit } from '@angular/core';
import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';
import { AuthService } from 'app/services/shared/auth/auth.service';
import { UserService } from 'app/services/user/user.service';
import { ModalShoppingService } from 'app/services/shopping/modal-shopping.service';
import { DataService } from 'app/services/shared/data/data.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'modal-user-forgotpass-form',
  templateUrl: './modal-user-forgotpass-form.component.html',
  styleUrls: ['./modal-user-forgotpass-form.component.css']
})
export class ModalUserForgotpassFormComponent implements OnInit {
  private className: string = this.constructor.name;

  constructor(
    private toastr: ToastrService,
    public userService: UserService,
    public modalShoppingService: ModalShoppingService,
    public shoppingService: ShoppingService,
    public auth: AuthService,
    private dataService: DataService,
    private debug: DebugService
  ) {
    this.debug.show(this.className, 'Iniciado...');
  }

  ngOnInit() {
  }

  sendPasswordRecovery(){
    const inputNewUserName: any = document.getElementById('rec_email');

    this.modalShoppingService.isLoading = true;
    if (this.dataService.isValidEmail(inputNewUserName.value)) {
      this.auth.ForgotPassword(inputNewUserName.value);
    } else {
      this.toastr.show("O e-mail informado é inválido", "Hungry Delivery", {
        enableHtml: true,
        closeButton: true,
        disableTimeOut:'timeOut',
        positionClass: 'toast-center-center',
        toastClass: 'ngx-toastr bg-red-to-orange-230 toaster-install'
      });
    }
    this.modalShoppingService.isLoading = false;
  }

  async previousCartStep(){
    this.shoppingService.previousCartStep();
    await this.shoppingService.delay(150);
    this.shoppingService.showForgotPasswordForm = false;
  }

  async showUserLoginForm(){
    this.shoppingService.previousCartStep();
    await this.shoppingService.delay(150);
    this.shoppingService.showNewUserForm = false;
    this.shoppingService.showForgotPasswordForm = false;
  }

  async showNewUserForm(){
    this.shoppingService.previousCartStep();
    await this.shoppingService.delay(150);
    this.shoppingService.showNewUserForm = true;
    this.shoppingService.showForgotPasswordForm = false;
  }

}
