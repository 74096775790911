import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoriteStoresComponent } from './favorite-stores/favorite-stores.component';
import { AllStoresComponent } from './all-stores/all-stores.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FavoriteStoresComponent, AllStoresComponent]
})
export class StoresModule { }
