import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { ROUTES } from '../../../app.routes';

import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ProfileContactComponent } from './profile-contact/profile-contact.component';
import { ProfileAddressComponent } from './profile-address/profile-address.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES)
  ],
  declarations: [ProfileDetailsComponent, ProfileContactComponent, ProfileAddressComponent]
})
export class UsersModule { }
