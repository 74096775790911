import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ShoppingService } from 'app/services/shopping/shopping.service';
import { DebugService } from 'app/services/shared/debug/debug.service';

declare var $: any;

@Component({
    selector: 'subitemlist',
    templateUrl: './subitem-template.component.html',
  })
  
  export class SubitemListComponent implements OnInit, AfterViewInit {
  
    constructor(
      public shoppingService: ShoppingService,
      private debug: DebugService) { }
  
    ngOnInit() { }
  
    fecharModal() {
      $('#subitemModal').modal('hide');
    }
  
    ngAfterViewInit() {
      this.shoppingService.getProductSubitems();
    }
  
    selectSubitem(subitem: Object | null) {
      if (subitem && this.shoppingService.acceptsMoreSubitems()) {
        this.shoppingService.selectSubitem(subitem);
      }
      $('#subitemModal').modal('hide');
    }
  
  }